import {
  SETTINGS_GET_REQUEST,
  SETTINGS_GET_SUCCESS,
  AUTH_LOGOUT,
  API_ERROR
} from '../actions/types';

import {createReducer} from '../utils';

const initialState = {
  email: '',
  email_to: '',
  email_cc: '',
  isFetching: false,
};
const authReducer = createReducer(initialState, {
  [SETTINGS_GET_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [SETTINGS_GET_SUCCESS]: (state, {settings}) => ({...state, ...settings, isFetching: false}),
  [AUTH_LOGOUT]: () => initialState,
  [API_ERROR]: state => ({...state, isFetching: false}),
});

export default authReducer;
