export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_TOS_REQUEST = 'AUTH_TOS_ACCEPT';
export const AUTH_TOS_ACCEPT = 'AUTH_TOS_ACCEPT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const PROFILE_GET_REQUEST = 'PROFILE_GET_REQUEST';
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS';

export const DASHBOARD_GET_REQUEST = 'DASHBOARD_GET_REQUEST';
export const JURISDICTION_GET_REQUEST = 'JURISDICTION_GET_REQUEST';
export const JURISDICTION_GET_SUCCESS = 'JURISDICTION_GET_SUCCESS';
export const JURISCDICTION_CONTACT_UPDATE_SUCCESS = 'JURISCDICTION_CONTACT_UPDATE_SUCCESS';
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_SET_SUCCESS';
export const PLAN_UPDATE_SUCCESS = 'PLAN_SET_SUCCESS';
export const DASHBOARD_PLANS_GET_SUCCESS = 'DASHBOARD_PLANS_GET_SUCCESS';

export const CURRENT_JURISDICTION_GET_REQUEST = 'CURRENT_JURISDICTION_GET_REQUEST';
export const CURRENT_JURISDICTION_GET_SUCCESS = 'CURRENT_JURISDICTION_GET_SUCCESS';

export const ACTIONS_GET_REQUEST = 'ACTIONS_GET_REQUEST';
export const ACTIONS_GET_SUCCESS = 'ACTIONS_GET_SUCCESS';
export const ACTION_GET_REQUEST = 'ACTION_GET_REQUEST';
export const ACTION_GET_SUCCESS = 'ACTION_GET_SUCCESS';
export const ACTION_GET_OPTIONS_SUCCESS = 'ACTION_GET_OPTIONS_SUCCESS';
export const ACTION_UPDATE_SUCCESS = 'ACTION_UPDATE_SUCCESS';
export const ACTIONS_UPDATE_REQUEST = 'ACTIONS_UPDATE_REQUEST';
export const ACTION_CREATE_REQUEST = 'ACTION_CREATE_REQUEST';
export const ACTION_CREATE_SUCCESS = 'ACTION_CREATE_SUCCESS';
export const ACTION_SAVE_FORM = 'ACTION_SAVE_FORM';
export const RESET_CURRENT_ACTION = 'RESET_CURRENT_ACTION';

export const SEARCH_OPTIONS_REQUEST = 'SEARCH_OPTIONS_REQUEST';
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_GET_SUCCESS = 'SEARCH_GET_SUCCESS';
export const SEARCH_GET_OPTIONS_SUCCESS = 'SEARCH_GET_OPTIONS_SUCCESS';
export const SEARCH_GET_OPTIONS_REQUEST = 'SEARCH_GET_OPTIONS_REQUEST';
export const SEARCH_GET_JURISDICTIONS_SUCCESS = 'SEARCH_GET_JURISDICTIONS_SUCCESS';
export const SEARCH_GET_ACTIONS_SUCCESS = 'SEARCH_GET_ACTIONS_SUCCESS';
export const SEARCH_GET_REQUEST = 'SEARCH_GET_REQUEST';
export const SEARCH_EXCEL_REQUEST = 'SEARCH_EXCEL_REQUEST';
export const SEARCH_EXCEL_SUCCESS = 'SEARCH_EXCEL_SUCCESS';

export const API_ERROR = 'API_ERROR';

export const APP_SHOW_PROFILE = 'APP_SHOW_PROFILE';
export const APP_HIDE_PROFILE = 'APP_HIDE_PROFILE';
export const APP_MESSAGE = 'APP_MESSAGE';

export const REPORT_GET_OPTIONS_REQUEST = 'REPORT_GET_OPTIONS_REQUEST';
export const REPORT_GET_OPTIONS_SUCCESS = 'REPORT_GET_OPTIONS_SUCCESS';
export const REPORT_GET_ACTIONS_REQUEST = 'REPORT_GET_ACTIONS_REQUEST';
export const REPORT_GET_ACTIONS_SUCCESS = 'REPORT_GET_ACTIONS_SUCCESS';
export const REPORT_GET_JURISDICTIONS_REQUEST = 'REPORT_GET_JURISDICTIONS_REQUEST';
export const REPORT_GET_JURISDICTIONS_SUCCESS = 'REPORT_GET_JURISDICTIONS_SUCCESS';
export const REPORT_GENERATE_REQUEST = 'REPORT_GENERATE_REQUEST';
export const REPORT_GENERATE_SUCCESS = 'REPORT_GENERATE_SUCCESS';

export const CONTENT_REQUEST = 'CONTENT_REQUEST';
export const ABOUT_SUCCESS = 'ABOUT_SUCCESS';
export const RESOURCES_SUCCESS = 'RESOURCES_SUCCESS';

export const SETTINGS_GET_REQUEST = 'SETTINGS_GET_REQUEST';
export const SETTINGS_GET_SUCCESS = 'SETTINGS_GET_SUCCESS';
