import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Empty, Spin} from 'antd';
import {ACTIONS_TABLE_PER_PAGE} from '../../../config/constants';
import {ActionsPaginationTable} from '../../../components';
import {getActions} from '../../../actions/actions';
import './index.css';

function PlanActionsPage(props) {
  const {
    onGetActions,
    items,
    currentPage,
    total,
    isFetching,
    sorterParams,
  } = props;

  useEffect(() => {
    onGetActions({
      size: ACTIONS_TABLE_PER_PAGE,
      page: 1,
      order: `actions.${sorterParams.field}_${sorterParams.order}`,
      sorterParams
    });
  }, []);

  function renderActions() {
    return (
      items.length
        ? (
          <ActionsPaginationTable
            actions={items}
            onGetActions={onGetActions}
            currentPage={currentPage}
            total={total}
            sorterParams={sorterParams}
          />
        )
        : (
          <Empty>
            <h2>Plan Actions</h2>
          </Empty>
        )
    );
  }

  return (
    isFetching
      ? <div className="spin__wrapper"><Spin size="large"/></div>
      : renderActions()
  );
}

PlanActionsPage.propTypes = {
  user: PropTypes.shape({}).isRequired,
  onGetActions: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  sorterParams: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({
  actions: {
    items,
    isFetching,
    currentPage,
    total,
    sorterParams
  }
}) => ({
  items,
  isFetching,
  currentPage,
  total,
  sorterParams,
});

const bindActions = dispatch => ({
  onGetActions: params => dispatch(getActions(params)),
});

export default connect(mapStateToProps, bindActions)(PlanActionsPage);
