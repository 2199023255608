import {Input} from 'antd';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';

const InputMask = props => (
  <ReactInputMask {...props}>
    { inputProps => <Input {...inputProps} disabled={props.disabled ? props.disabled : null}/> }
  </ReactInputMask>
);

InputMask.propTypes = {
  mask: PropTypes.string.isRequired,
  maskChar: PropTypes.string,
  formatChars: PropTypes.object,
  alwaysShowMask: PropTypes.bool,
  inputRef: PropTypes.func,
  disabled: PropTypes.bool,
};

export default InputMask;
