import ApiClient from '../../utils/api';
import {
  API_ERROR,
  SETTINGS_GET_REQUEST,
  SETTINGS_GET_SUCCESS,
} from '../types';
import {API_CONTACT_US, API_PLANS_VIEW} from '../../config/constants';

export function getSettings() {
  return async (dispatch) => {
    try {
      dispatch({type: SETTINGS_GET_REQUEST});

      const [email_to, is_state_view] = await Promise.all([
        ApiClient.get(API_CONTACT_US),
        ApiClient.get(API_PLANS_VIEW)
      ]);
      const settings = {
        ...email_to,
        ...is_state_view
      };

      dispatch({type: SETTINGS_GET_SUCCESS, settings});
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}
