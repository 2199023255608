import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import {
  Layout,
  Menu,
  Icon,
} from 'antd';
import './index.css';
import mainNav from '../../../config/side-menu';
import whiteBALogo from '../../../assets/images/BATool-logo-1.svg';
import ttLogo from '../../../assets/images/TT_logo-2.svg';

const {
  Sider,
} = Layout;

function renderMenuItem(item, index) {
  return (
    <Menu.Item key={index}>
      <Icon type={item.icon}/>
      <span>{item.title}</span>
      {item.path && <Link to={item.path}/>}
      {item.href && <a href={item.href} target={item.target || ''}>&nbsp;</a>}
    </Menu.Item>
  );
}

function MainNav(props) {
  const [collapsed, setCollapsed] = useState(false);
  const {settings, role} = props;

  const onCollapsed = () => {
    setCollapsed(!collapsed);
  };

  mainNav.contact.href = `mailto:${settings.email}?bcc=batoolhelp@tetratech.com&Subject=Contact%20Us%20regarding%20HMP%20Progress%20Reporting%20Tool`;

  if (role !== 'standard_user' && role) {
    mainNav.admin = {
      title: 'Admin',
      href: '/admin',
      icon: 'dashboard',
      target: '_blank'
    };
  } else {
    mainNav.admin = {};
  }

  return (
    <Sider
      width={224}
      className="main-nav"
      collapsed={collapsed}
      collapsible
      onCollapse={onCollapsed}
    >
      <div className="main-nav__logo-top"><img src={ttLogo} alt=""/></div>
      <Menu
        className="main-nav__menu"
        defaultSelectedKeys={['0']}
        mode="inline"
        theme="dark"
      >
        {Object.values(mainNav).map(renderMenuItem)}
      </Menu>
      <div className="main-nav__logo-bottom"><img src={whiteBALogo} alt=""/></div>

    </Sider>
  );
}

MainNav.propTypes = {
  settings: PropTypes.shape({}).isRequired,
  role: PropTypes.string.isRequired,
};

export default withRouter(MainNav);
