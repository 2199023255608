import React from 'react';
import {Form, Select} from 'antd';
import {ACTION_FORM_FIELDS} from '../../../config/constants';
import {shouldDisableField} from '../../../utils/shouldDisableField';
import {labelSpan} from './LabelSpan';

const {Option} = Select;

export function SelectValidated(props) {
  const {
    label, field, optionName, mode, hasTooltip, currentAction, options, form: {getFieldDecorator}
  } = props;

  return (
    <Form.Item
      label={labelSpan(label, optionName, hasTooltip, 'ant-form-item-required')}
      labelCol={{span: 8}}
    >
      {
        getFieldDecorator(field, {
          rules: [
            {
              validator: (rule, value, callback) => {
                validateSelect(props, rule, value, callback, optionName);
              },
            }
          ],
          initialValue: currentAction[field]
        })(
          <Select id={field} mode={String(mode)} disabled={shouldDisableField(props, field)} optionFilterProp="label">
            {
              options[optionName].map(item => (
                <Option key={item.id} value={item.id} label={item.label}>{item.label}</Option>
              ))
            }
          </Select>
        )
      }
    </Form.Item>
  );
}

function validateSelect(props, rule, value, callback, optionName) {
  try {
    const selectValue = renderFromOptions(props, value, optionName);
    const fieldError = ACTION_FORM_FIELDS[rule.field];

    if (!selectValue) {
      throw new Error(`${fieldError} is required`);
    }
    if (selectValue === '-') {
      throw new Error(`${fieldError} cannot have '-' as a response`);
    }
    callback();
  } catch (err) {
    callback(err);
  }
}

function renderFromOptions(props, name, field) {
  const {options} = props;

  if (!name) {
    return null;
  }

  const selectedOptions = Array.isArray(name)
    ? name.map(
      item => options[field].find(opt => opt.id === item)
    )
    : options[field].find(opt => opt.id === name);

  if (!selectedOptions) {
    return null;
  }

  return Array.isArray(selectedOptions)
    ? selectedOptions.map(item => item.label).join(', ')
    : selectedOptions.label;
}
