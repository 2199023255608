import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Table,
  Button,
  Icon,
} from 'antd';
import moment from 'moment';
import {TABLE_PER_PAGE} from '../../config/constants';

const PlansTable = (props) => {
  const {plans} = props;

  const columns = [
    // {
    //   title: 'Plan Owner',
    //   dataIndex: 'plan_owner',
    //   key: 'plan_owner',
    //   render: (plan_owner, entry) => (
    //     <Link to={`/dashboard/jurisdiction/${entry.jurisdiction_id}`}>
    //       <Button icon="edit" type="link">{plan_owner}</Button>
    //     </Link>
    //   ),
    //   sorter: (a, b) => a.plan_owner < b.plan_owner,
    //   sortDirections: ['ascend', 'descend'],
    //   // width: '10%'
    // },
    {
      title: 'Plan Name',
      dataIndex: 'plan_name',
      key: 'plan_name',
      render: (plan, entry) => (
        <Link to={`/dashboard/jurisdiction/${entry.jurisdiction_id}`}>
          <Button className="jurisdiction__button" icon="edit" type="link">{entry.plan.name}</Button>
        </Link>
      ),
      sorter: (a, b) => {
        if (a.plan.name > b.plan.name) {
          return 1;
        }
        if (a.plan.name < b.plan.name) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '20%'
    },
    {
      title: 'Plan Approval Date',
      dataIndex: 'approval_date',
      key: 'approval_date',
      render: (plan, entry) => (<span>{moment(entry.plan.approval_date).format('MM/DD/YYYY')}</span>),
      sorter: (a, b) => {
        if (a.plan.approval_date > b.plan.approval_date) {
          return 1;
        }
        if (a.plan.approval_date < b.plan.approval_date) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '10%'
    },
    {
      title: 'Plan Expiration Date',
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      render: (plan, entry) => (<span>{moment(entry.plan.expiration_date).format('MM/DD/YYYY')}</span>),
      sorter: (a, b) => {
        if (a.plan.expiration_date > b.plan.expiration_date) {
          return 1;
        }
        if (a.plan.expiration_date < b.plan.expiration_date) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '10%'
    },
    {
      title: 'Review Cycle Start Date',
      dataIndex: 'plan',
      key: 'plan',
      render: (plan, entry) => (<span>{moment(entry.plan.review_start_date).format('MM/DD/YYYY')}</span>),
      sorter: (a, b) => {
        if (a.plan.review_start_date > b.plan.review_start_date) {
          return 1;
        }
        if (a.plan.review_start_date < b.plan.review_start_date) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '10%'
    },
    {
      title: 'Review Cycle End Date',
      dataIndex: 'plan',
      key: 'plan2',
      render: (plan, entry) => (<span>{moment(entry.plan.review_end_date).format('MM/DD/YYYY')}</span>),
      sorter: (a, b) => {
        if (a.plan.review_end_date > b.plan.review_end_date) {
          return 1;
        }
        if (a.plan.review_end_date < b.plan.review_end_date) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '10%'
    },
    {
      title: '# Review In-Progress',
      dataIndex: 'review_in_progress',
      key: 'review_in_progress',
      sorter: (a, b) => {
        if (a.review_in_progress > b.review_in_progress) {
          return 1;
        }
        if (a.review_in_progress < b.review_in_progress) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '10%'
    },
    {
      title: 'Review Complete',
      dataIndex: 'review_complete',
      key: 'review_complete',
      sorter: (a, b) => {
        if (a.review_complete > b.review_complete) {
          return 1;
        }
        if (a.review_complete < b.review_complete) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '10%'
    },
    {
      title: '% Review Complete',
      dataIndex: 'completed_review_percentage',
      key: 'completed_review_percentage',
      render: completed => (<span>{`${completed}%`}</span>),
      sorter: (a, b) => {
        if (a.completed_review_percentage > b.completed_review_percentage) {
          return 1;
        }
        if (a.completed_review_percentage < b.completed_review_percentage) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '10%'
    },
    {
      title: 'Annual Plan Review Report Submitted',
      dataIndex: 'annual_plan_review_report_submitted',
      key: 'annual_plan_review_report_submitted',
      sorter: (a, b) => {
        if (a.annual_plan_review_report_submitted > b.annual_plan_review_report_submitted) {
          return 1;
        }
        if (a.annual_plan_review_report_submitted < b.annual_plan_review_report_submitted) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '10%'
    }
  ];

  return (
    <>
      <div className="status__table-note">
        Click&nbsp;
        <Icon type="edit"/>
        &nbsp;to access plan details
      </div>
      <div className="table__wrapper table__wrapper-full">
        <Table columns={columns} dataSource={plans} pagination={{pageSize: TABLE_PER_PAGE}}/>
      </div>
    </>
  );
};

PlansTable.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRouter(PlansTable);
