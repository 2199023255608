const API_PREFIX = '/api/v1';
const API_PAGES = `${API_PREFIX}/pages`;
const API_DASHBOARD = `${API_PREFIX}/dashboard`;

export const API_LOGIN = `${API_PREFIX}/login`;
export const API_PROFILE = `${API_PREFIX}/profile`;
export const API_JURISDICTION = `${API_PREFIX}/jurisdictions/:id`;
export const API_PLAN = `${API_PREFIX}/plan/:id`;
export const API_CONTACT = `${API_PREFIX}/contact`;
export const API_ABOUT = `${API_PAGES}/about`;
export const API_RESOURCES = `${API_PAGES}/resource`;
export const API_SETTINGS = `${API_PREFIX}/settings`;
export const API_CONTACT_US = `${API_PREFIX}/contact_us`;
export const API_PLANS_VIEW = `${API_DASHBOARD}/is-plans`;
export const API_DASHBOARD_PLANS = `${API_DASHBOARD}/plans`;
export const API_JURISDICTION_WITH_RELATED_DETAILS = `${API_DASHBOARD}/jurisdictions`;

export const API_TERMS = `${API_PROFILE}/terms`;

export const API_ACTION_DROPDOWNS = `${API_PREFIX}/action/dropdowns`;
export const API_ACTIONS = `${API_PREFIX}/actions`;
export const API_ACTION = `${API_ACTIONS}/:id`;

export const API_REPORT_DROPDOWNS = `${API_PREFIX}/search/dropdowns`;
export const API_REPORT = `${API_PREFIX}/actions/search`;
export const API_REPORT_JURISDICTIONS = `${API_PREFIX}/jurisdictions/by_jurisdiction_ids`;
export const API_GENERATE_REPORT = `${API_PREFIX}/report/download`;

export const API_PLAN_ACTIONS = `${API_PREFIX}/plan/actions`;

export const API_SEARCH = `${API_PREFIX}/actions/search`;
export const API_SEARCH_DROPDOWNS = `${API_PREFIX}/search/dropdowns`;
export const API_SEARCH_JURISDICTIONS = `${API_PREFIX}/jurisdictions/by_jurisdiction_ids`;
export const API_SEARCH_ACTIONS = `${API_PREFIX}/actions/by_jurisdiction_ids`;
export const API_SEARCH_EXCEL = `${API_PREFIX}/actions/export_search`;

export const DATE_FORMAT = 'MM-DD-YYYY';
export const DATE_ALTERNATIVE_FORMAT = 'YYYY-MM-DD';
export const REPORT_FORM_LAYOUT = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
    md: {span: 12}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
    md: {span: 12}
  },
};
export const REPORT_TABLE_PER_PAGE = 10;
export const MODAL_FORM_LAYOUT = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
    md: {span: 12}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
    md: {span: 12}
  },
};
export const SEARCH_FORM_LAYOUT = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
    md: {span: 12}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
    md: {span: 12}
  },
};
export const ACTION_FORM_LAYOUT = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
    md: {span: 9}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 13},
    md: {span: 12}
  },
};

export const JURSDICTION_PROGRESS_TABLE = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '50%'
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: '50%'
  }
];

export const ACTIONS_TABLE_CONFIG = {
  bordered: true,
  loading: false,
  pagination: false,
  size: 'small',
  title: undefined,
  showHeader: false,
};

export const PHONE_PATTERN = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const EMAIL_SUBJECT = 'Contact Us regarding HMP Progress Reporting Tool';

export const TABLE_VISIBLE_PART = '222px';
export const TABLE_PER_PAGE = 10;
export const ACTIONS_TABLE_PER_PAGE = 10;
export const SEARCH_TABLE_PER_PAGE = 10;

export const SEARCH_RADIO = {
  condition: [
    {
      id: 'and',
      name: 'And'
    },
    {
      id: 'or',
      name: 'Or'
    }
  ],
  include_history: [
    {
      id: true,
      name: 'Yes'
    },
    {
      id: false,
      name: 'No'
    }
  ]
};

export const ACTION_ID_NOTE = '<p>Please enter an Action ID in this format (Year-Jurisdiction–Number) showing current year, name of jurisdiction, and action number. For example, 2021-Anne Village-009.</p>';
export const ACTION_NOTE = '<p>Please review and update this action by following the steps below and updating any/all fields (as needed).</p>';
export const SAVE_NOTE = '<p>To save, you can either:<ol><li>Save Draft - click this button to save changes and return later to complete;</li><li>Save & Submit - click this button to submit your changes and finish the reporting for the review cycle.</li></ol>';
export const ACTION_FORM_FIELDS = {
  reviewer: 'Reviewer',
  action_status_id: 'Action Status',
  description: 'Description of the Action',
  lead_agency: 'Lead Agency',
  priority_option_id: 'Priority',
  hazard_options: 'Hazard(s) Mitigated',
  benefit_option_id: 'Benefits',
  cost_option_id: 'Cost',
  timeline_option_id: 'Timeline',
  asset_type_id: 'Assets / Structures',
  name: 'Action ID',
  grant_funding_source_id: 'Grant Funding Source',
  grant_funded: 'Grant funded',
  level_protection: 'Level Protection',
  losses_avoided: 'Losses Avoided',
  benefit_cost_analysis: 'Benefit Cost Analysis',
  bca_ratio: 'BCA ratio',
  bca_total_benefits: 'BCA total benefits',
  bca_final_cost: 'BCA final cost',
  grant_name: 'Grant Name',
  other_funding_source: 'Other Funding Source'
};

export const HINTS = {
  grant_funded: {
    name: 'Description of filters',
    hint: `Selecting <strong>And</strong> will contain results with all options selected in the multi-select drop down lists.<br>
    Selecting <strong>Or</strong> will contain results with any of the selected options.`
  },
  level_protection: {
    name: 'Description of filters',
    hint: `Selecting <strong>And</strong> will contain results with all options selected in the multi-select drop down lists.<br>
    Selecting <strong>Or</strong> will contain results with any of the selected options.`
  },
  losses_avoided: {
    name: 'Description of filters',
    hint: `Selecting <strong>And</strong> will contain results with all options selected in the multi-select drop down lists.<br>
    Selecting <strong>Or</strong> will contain results with any of the selected options.`
  },
  benefit_cost_analysis: {
    name: 'Description of filters',
    hint: `Selecting <strong>And</strong> will contain results with all options selected in the multi-select drop down lists.<br>
    Selecting <strong>Or</strong> will contain results with any of the selected options.`
  },
  bca_ratio: {
    name: 'Description of filters',
    hint: `Selecting <strong>And</strong> will contain results with all options selected in the multi-select drop down lists.<br>
    Selecting <strong>Or</strong> will contain results with any of the selected options.`
  },
  bca_total_benefits: {
    name: 'Description of filters',
    hint: `Selecting <strong>And</strong> will contain results with all options selected in the multi-select drop down lists.<br>
    Selecting <strong>Or</strong> will contain results with any of the selected options.`
  },
  bca_final_cost: {
    name: 'Description of filters',
    hint: `Selecting <strong>And</strong> will contain results with all options selected in the multi-select drop down lists.<br>
    Selecting <strong>Or</strong> will contain results with any of the selected options.`
  },
  grant_name: {
    name: 'Description of filters',
    hint: `Selecting <strong>And</strong> will contain results with all options selected in the multi-select drop down lists.<br>
    Selecting <strong>Or</strong> will contain results with any of the selected options.`
  },
  other_funding_source: {
    name: 'Description of filters',
    hint: `Selecting <strong>And</strong> will contain results with all options selected in the multi-select drop down lists.<br>
    Selecting <strong>Or</strong> will contain results with any of the selected options.`
  },
  condition: {
    name: 'Description of filters',
    hint: `Selecting <strong>And</strong> will contain results with all options selected in the multi-select drop down lists.<br>
    Selecting <strong>Or</strong> will contain results with any of the selected options.`
  },
  hazard_option: {
    name: 'Options to search Hazard(s) Mitigated field',
    hint: `To search actions with any and all hazards in the plan, leave the <i>Hazards Mitigated</i> field blank.<br>
    By selecting 'All' or 'All Hazards' from the dropdown list, the only results returned will be actions with 'All' or 'All Hazards' assigned to the <i>Hazards Mitigated</i> field.`
  },
  mitigation_option: {
    name: 'FEMA Mitigation Action Category Definitions',
    hint: `<ul>
    <li><strong>Local Plans and Regulations (LPR)</strong> – These actions include government authorities, policies or codes that influence the way land and buildings are being developed and built.</li>
    <li><strong>Structure and Infrastructure Project (SIP)</strong> - These actions involve modifying existing structures and infrastructure to protect them from a hazard or remove them from a hazard area. This could apply to public or private structures as well as critical facilities and infrastructure. This type of action also involves projects to construct manmade structures to reduce the impact of hazards.</li>
    <li><strong>Natural Systems Protection (NSP)</strong> – These are actions that minimize damage and losses, and also preserve or restore the functions of natural systems.</li>
    <li><strong>Education and Awareness Programs (EAP)</strong> – These are actions to inform and educate citizens, elected officials, and property owners about hazards and potential ways to mitigate them. These actions may also include participation in national programs, such as StormReady and Firewise Communities.</li>
    </ul>`
  },
  crs_option: {
    name: 'CRS Category Definitions',
    hint: `<ul>
    <li><strong>Preventative Measures (PR)</strong> - Government, administrative or regulatory actions, or processes that influence the way land and buildings are developed and built. Examples include planning and zoning, floodplain local laws, capital improvement programs, open space preservation, and storm water management regulations.</li>
    <li><strong>Property Protection (PP)</strong> - These actions include public activities to reduce hazard losses or actions that involve (1) modification of existing buildings or structures to protect them from a hazard or (2) removal of the structures from the hazard area. Examples include acquisition, elevation, relocation, structural retrofits, storm shutters, and shatter-resistant glass.</li>
    <li><strong>Public Information (PI)</strong> - Actions to inform and educate citizens, elected officials, and property owners about hazards and potential ways to mitigate them. Such actions include outreach projects, real estate disclosure, hazard information centers, and educational programs for school-age children and adults.</li>
    <li><strong>Natural Resource Protection (NR)</strong> - Actions that minimize hazard loss and also preserve or restore the functions of natural systems. These actions include sediment and erosion control, stream corridor restoration, watershed management, forest and vegetation management, and wetland restoration and preservation.</li>
    <li><strong>Structural Flood Control Projects (SP)</strong> - Actions that involve the construction of structures to reduce the impact of a hazard. Such structures include dams, setback levees, floodwalls, retaining walls, and safe rooms.</li>
    <li><strong>Emergency Services (ES)</strong> - Actions that protect people and property during and immediately following a disaster or hazard event. Services include warning systems, emergency response services, and the protection of essential facilities.</li>
    </ul>`
  },
  goals: {
    name: 'Goals and/or Objectives',
    hint: 'Please separate each by a comma.'
  },
  action_status: {
    name: 'Action Status',
    hint: `This graphic represents the implementation status of each project/action.
          <ul>
          <li><strong>No progress</strong>: There has been no progress in implementing the project/action.</li>
          <li><strong>In progress</strong>: There has been some progress in implementing the project/action. Provide details in the 'Project/Action Status Comments' field.</li>
          <li><strong>Completed</strong>: This project/action has been completed.</li>
          <li><strong>Discontinued</strong>: This project/action is no longer valid and will be discontinued (e.g., no longer a priority; not feasible).</li>
          <li><strong>Ongoing Capability</strong>: This project/action is something your jurisdiction completes on a continual or regular basis as part of your operations. Select ‘Completed’ if the ongoing capability was completed during this reporting period.</li>
          </ul>`
  },
  reporting_progress: {
    name: 'Reporting Progress',
    hint: 'This graphic represents the progress of action review.'
  }
};
