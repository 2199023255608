import ApiClient from '../../utils/api';
import {
  API_ERROR,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_TOS_ACCEPT,
} from '../types';
import {API_LOGIN, API_TERMS} from '../../config/constants';

export function loginUser({userName: username, password}) {
  return async (dispatch) => {
    try {
      dispatch({type: API_ERROR, error: null});
      dispatch({type: AUTH_REQUEST});
      const {jwt: token, user} = await ApiClient.post(API_LOGIN, {username, password});
      dispatch({type: AUTH_SUCCESS, token, user});
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function onToSAccept() {
  return async (dispatch) => {
    try {
      await ApiClient.put(API_TERMS, {terms: true});
      dispatch({type: AUTH_TOS_ACCEPT});
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function logoutUser() {
  return {
    type: AUTH_LOGOUT
  };
}
