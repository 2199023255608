import React from 'react';
import './index.css';
import AttributeChangesTable from '../AttributeChangesTable/index.jsx';

function FormattedChangeTime(props) {
  const date = new Date(props.unix_time * 1000)

  return (
    <time datetime={date.toISOString()}>
      {date.toDateString()} at {date.toLocaleTimeString()}
    </time>
  )
}

export default function ActionChangesTable(props) {
  return (
    <table className="action-changes__table">
      <thead>
        <tr>
          <th className="action-changes__th" style={{ width: '20ch' }}>Modify Date</th>
          <th className="action-changes__th" style={{ width: '25ch' }}>Reviewer</th>
          <th className="action-changes__th">Changes</th>
        </tr>
      </thead>
      <tbody>
        {props.changes.map((change, index) => (
          <tr data-row-key={change.review_ids.join(' → ')}>
            <td className="action-changes__td"><FormattedChangeTime unix_time={change.time_unix} /></td>
            <td className="action-changes__td">{change.reviewer}</td>
            <td className="action-changes__td">
              {(Object.keys(change.changes).length > 0) ? <AttributeChangesTable change={change} /> : 'No changes'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
