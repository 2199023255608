import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Form, Spin} from 'antd';
import {
  getAction,
  getActionOptions,
  createAction,
  currentActionReset
} from '../../../actions/actions';
import {ActionForm} from '../../../components';

function ActionCreatePage(props) {
  const {
    onCurrentActionReset,
    onGetActionOptions,
    onCreateAction,
    currentAction,
    currentAction: {
      jurisdiction_name
    },
    options,
    match: {
      params: {
        id: jurisdictionId
      }
    },
  } = props;

  useEffect(() => {
    onGetActionOptions();
    onCurrentActionReset(jurisdiction_name);
  }, []);

  const ActionFormComponent = Form.create({name: 'action'})(ActionForm);

  return (
    <>
      {
        !options
          ? (<div className="spin__wrapper"><Spin size="large"/></div>)
          : (
            <ActionFormComponent
              onCreateAction={onCreateAction}
              currentAction={currentAction}
              options={options}
              id={jurisdictionId}
              create
            />
          )
      }
    </>
  );
}

ActionCreatePage.propTypes = {
  onCreateAction: PropTypes.func.isRequired,
  onGetActionOptions: PropTypes.func.isRequired,
  onCurrentActionReset: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape({}).isRequired,
  currentAction: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({
  actions: {
    isFetching,
    options,
    currentAction
  }
}) => ({
  isFetching,
  options,
  currentAction
});

const bindActions = dispatch => ({
  onGetAction: params => dispatch(getAction(params)),
  onGetActionOptions: () => dispatch(getActionOptions()),
  onCreateAction: params => dispatch(createAction(params)),
  onCurrentActionReset: params => dispatch(currentActionReset(params)),
});

export default connect(mapStateToProps, bindActions)(ActionCreatePage);
