import React from 'react';
import {withRouter} from 'react-router-dom';
import {Breadcrumb, Icon} from 'antd';
import './index.css';

function Breadcrumbs() {
  return (
    <Breadcrumb className="breadcrumbs">
      <Breadcrumb.Item href="">
        <Icon type="home"/>
      </Breadcrumb.Item>
      <Breadcrumb.Item href="">
        <span>Dashboard</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Plans</Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default withRouter(Breadcrumbs);
