import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {Switch, Route, Router} from 'react-router-dom';
import {store, history, persistor} from './store';
import {
  LoginContainer,
  RootContainer,
} from './containers';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error) {
    console.log(error);
  }

  render() {
    const {hasError} = this.state;

    if (hasError) {
      return <h1>Something went wrong</h1>;
    }

    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <Switch>
              <Route exact path="/login" component={LoginContainer}/>
              <Route path="/" component={RootContainer}/>
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}
