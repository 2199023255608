import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Form,
} from 'antd';
import PointOfContactForm from './PointOfContactForm';

const POCForm = Form.create()(PointOfContactForm);

const PointOfContact = (props) => {
  const {contact, onSaveContact, jurisdictionId} = props;
  const [isVisible, toggleVisibility] = useState(false);

  function toggleModal() {
    toggleVisibility(!isVisible);
  }

  return (
    <Fragment>
      <Button type="primary" icon="form" onClick={toggleModal}>POC</Button>
      <Modal
        visible={isVisible}
        title="Point of Contact"
        width={900}
        onCancel={toggleModal}
        bodyStyle={{
          minHeight: 400,
          overflowY: 'auto'
        }}
        footer={null}
      >
        <POCForm
          contact={contact}
          onSaveContact={onSaveContact}
          toggleModal={toggleModal}
          jurisdictionId={jurisdictionId}
        />
      </Modal>
    </Fragment>
  );
};

PointOfContact.propTypes = {
  contact: PropTypes.shape({}).isRequired,
  onSaveContact: PropTypes.func.isRequired,
  jurisdictionId: PropTypes.number.isRequired
};

export default PointOfContact;
