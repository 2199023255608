import {createStore, applyMiddleware, compose} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import {persistStore} from 'redux-persist';
import persistedReducer from '../reducers';

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const initialState = preloadedState || {};
export const history = createBrowserHistory({basename: '/'});

const middleware = [
  applyMiddleware(routerMiddleware(history)),
  applyMiddleware(thunk)
];

if (process.env.NODE_ENV !== 'production') {
  middleware.push(applyMiddleware(logger));
}

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  middleware.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const _store = createStore(
  persistedReducer(history),
  initialState,
  compose(...middleware)
);

if (module.hot) {
  module.hot.accept('../reducers', () => {
    const nextRootReducer = require('../reducers').default;
    _store.replaceReducer(nextRootReducer);
  });
}
export const persistor = persistStore(_store);
export const store = _store;

