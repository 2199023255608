import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {
  Row,
  Col,
  Typography,
  Statistic,
  Empty,
} from 'antd';
import PropTypes from 'prop-types';
import './index.css';
import {
  ActionsTable,
  JurisdictionsTable,
  PointOfContact,
  Plan,
  JurisdictionsCharts,
  ChartCard,
  PlansTable,
} from '../../../components';
import {
  saveContact,
  savePlan,
  getPlans,
  getJurisdictionWithDetails,
} from '../../../actions/dashboard';

const {Title} = Typography;

function DashboardPage(props) {
  const {
    user: {
      role
    },
    name: jurisdictionName,
    contact,
    descendants,
    plan,
    onSaveContact,
    onSavePlan,
    onGetPlans,
    actions,
    descendants_chart_statuses,
    actions_chart_by_review,
    actions_chart_by_status,
    onGetJurisdictionWithDetails,
    jurisdictionType,
    isPlansView,
    plans,
    id
  } = props;

  function gotState() {
    return isPlansView !== undefined;
  }

  function getData() {
    if (!gotState()) {return;}
    if (isPlansView) {
      onGetPlans();
    } else {
      onGetJurisdictionWithDetails();
    }
  }

  useEffect(() => {
    getData();
  }, []);

  function renderJurisdiction() {
    return (
      <>
        {
          jurisdictionName
            ? (
              <Row className="dashboard__header">
                <Col span={12}>
                  <Title level={3} className="dashboard__heading">
                    {`${jurisdictionName}`}
                  </Title>
                </Col>
                {
                  jurisdictionType !== 'plan' && (
                    <>
                      <Col span={3} className="buttons-gutter">
                        <PointOfContact
                          contact={contact}
                          onSaveContact={onSaveContact}
                          jurisdictionId={id}
                        />
                      </Col>
                      <Col span={7} className="dasboard__contacts">
                        <Col span={12} className="dasboard__contacts_primary">
                          <Statistic
                            title="Primary POC:"
                            value={
                              contact
                                ? `${contact.primary_first_name} ${contact.primary_last_name}`
                                : 'Empty'
                            }
                          />
                        </Col>
                        <Col span={12} className="dasboard__contacts_alternate">
                          <Statistic
                            title="Alternate POC:"
                            value={
                              contact
                                ? `${contact.alternate_first_name} ${contact.alternate_last_name}`
                                : 'Empty'
                            }
                          />
                        </Col>
                      </Col>
                      <Col span={2} className="ta-r">
                        <Plan plan={plan} onSavePlan={onSavePlan} role={role}/>
                      </Col>
                    </>
                  )
                }
              </Row>
            )
            : null
        }
        {
          descendants.length
            ? (
              <>
                <JurisdictionsCharts jurisdictions={descendants_chart_statuses} plan={plan} title="Reporting Progress"/>
                <JurisdictionsTable
                  currentJurisdictionId={id}
                  jurisdictions={descendants}
                  isScrollable={Boolean(actions.length)}
                />
              </>
            )
            : null
        }
        {
          actions.length
            ? (
              <>
                <Row type="flex">
                  <Col span={12} className="status__wrapper">
                    <ChartCard data={actions_chart_by_review} title="Reporting Progress"/>
                  </Col>
                  <Col span={12} className="status__wrapper">
                    <ChartCard data={actions_chart_by_status} title="Action Status"/>
                  </Col>
                </Row>
                <ActionsTable actions={actions} jurisdictionId={id}/>
              </>
            )
            : null
        }
        {
          !actions.length && !descendants.length
            ? (
              <Empty>
                <Title level={3}>There are no actions</Title>
              </Empty>
            )
            : null
        }
      </>
    );
  }

  function renderPlans() {
    return (
      plans.length
        ? (
          <>
            <Row className="dashboard__header table__wrapper-full">
              <Title level={3} className="dashboard__heading-inline">
                Management Dashboard
              </Title>
            </Row>
            <PlansTable plans={plans} isPlan={plans.length}/>
          </>
        )
        : null
    );
  }

  function renderContent() {
    return isPlansView
      ? renderPlans()
      : renderJurisdiction();
  }

  return (
    gotState()
      ? renderContent()
      : null
  );
}

DashboardPage.propTypes = {
  user: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  contact: PropTypes.shape({}).isRequired,
  plan: PropTypes.shape({}).isRequired,
  onSaveContact: PropTypes.func.isRequired,
  onSavePlan: PropTypes.func.isRequired,
  onGetPlans: PropTypes.func.isRequired,
  onGetJurisdictionWithDetails: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  descendants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  descendants_chart_statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actions_chart_by_review: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actions_chart_by_status: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isPlansView: PropTypes.bool,
  jurisdictionType: PropTypes.bool,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({
  auth: {user},
  dashboard: {
    plan,
    name,
    descendants_chart_statuses,
    actions_chart_by_review,
    actions_chart_by_status,
    descendants,
    actions,
    contact,
    jurisdiction,
    plans,
    type: jurisdictionType,
    id
  },
  settings: {
    is_plans_view: isPlansView,
  },
}) => ({
  jurisdiction,
  actions,
  contact,
  descendants,
  user,
  plan,
  name,
  descendants_chart_statuses,
  actions_chart_by_review,
  actions_chart_by_status,
  isPlansView,
  plans,
  jurisdictionType,
  id
});

const bindActions = dispatch => ({
  onGetJurisdictionWithDetails: () => dispatch(getJurisdictionWithDetails()),
  onSaveContact: params => dispatch(saveContact(params)),
  onSavePlan: params => dispatch(savePlan(params)),
  onGetPlans: () => dispatch(getPlans()),
});

export default connect(mapStateToProps, bindActions)(DashboardPage);
