import {
  REPORT_GET_OPTIONS_REQUEST,
  REPORT_GET_OPTIONS_SUCCESS,
  REPORT_GET_ACTIONS_REQUEST,
  REPORT_GET_ACTIONS_SUCCESS,
  REPORT_GET_JURISDICTIONS_REQUEST,
  REPORT_GET_JURISDICTIONS_SUCCESS,
  REPORT_GENERATE_REQUEST,
  REPORT_GENERATE_SUCCESS,
  API_ERROR,
} from '../actions/types';

import {createReducer} from '../utils';

const initialState = {
  availableJurisdictions: [],
  actions: [],
  currentPage: 1,
  isFetching: false,
  isReportRuns: false,
  params: {
    plans: [],
    jurisdiction_id: [],
  },
  searchSubmitted: false
};

const reportReducer = createReducer(initialState, {
  [REPORT_GET_OPTIONS_REQUEST]: () => ({
    ...initialState,
    isFetching: true,
  }),
  [REPORT_GET_OPTIONS_SUCCESS]: (state, {options, availableJurisdictions, jurisdictions}) => ({
    ...state,
    isFetching: false,
    availableJurisdictions,
    options: {
      ...options,
      jurisdictions,
    }
  }),
  [REPORT_GET_JURISDICTIONS_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [REPORT_GET_JURISDICTIONS_SUCCESS]: (state, {jurisdictions, params}) => ({
    ...state,
    isFetching: false,
    options: {
      ...state.options,
      jurisdictions,
    },
    params,
  }),
  [REPORT_GET_ACTIONS_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [REPORT_GET_ACTIONS_SUCCESS]: (state, {actions, total, params}) => ({
    ...state,
    isFetching: false,
    actions,
    total,
    currentPage: params.page,
    params,
    searchSubmitted: true,
  }),
  [REPORT_GENERATE_REQUEST]: state => ({
    ...state,
    isReportRuns: true,
  }),
  [REPORT_GENERATE_SUCCESS]: state => ({
    ...state,
    isReportRuns: false,
  }),
  [API_ERROR]: state => ({
    ...state,
    isFetching: false,
    isReportRuns: false,
  }),
});

export default reportReducer;
