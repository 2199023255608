import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import {Table, Button, Tag} from 'antd';
import moment from 'moment';
import {SEARCH_TABLE_PER_PAGE} from '../../config/constants';
import './index.css';

const ActionsSearchTable = (props) => {
  const {
    actions,
    getSearchItems,
    currentPage,
    total,
    options,
    params,
    availableJurisdictions,
    sorterParams,
  } = props;
  const sortOrder = sorterParams.order === 'asc' ? 'ascend' : 'descend';

  const renderFromOptions = (name, field) => {
    if (!name) {
      return null;
    }

    const selectedOptions = Array.isArray(name)
      ? name.map(
        item => options[field].find(opt => opt.id === item)
      )
      : options[field].find(opt => opt.id === name);

    if (!selectedOptions) {
      return null;
    }

    return Array.isArray(selectedOptions)
      ? selectedOptions.map(item => item.label).join(', ')
      : selectedOptions.label;
  };

  const jurisdictionNameById = (id) => {
    const jr = availableJurisdictions.find(item => item.id === id)
    return jr ? jr.name : null
  }

  const columns = [
    {
      title: 'Jurisdiction',
      dataIndex: 'jurisdiction_id',
      key: 'jurisdictions.name',
      render: id => (
        <Link to={`/dashboard/jurisdiction/${id}`}>
          <Button className="jurisdiction__button" icon="edit" type="link">
            {jurisdictionNameById(id)}
          </Button>
        </Link>
      ),
      width: 200,
      sortOrder: sorterParams.field === 'jurisdictions.name' && sortOrder,
      sorter: true,
    },
    {
      title: 'Plan',
      dataIndex: 'plan_name',
      key: 'plan_name',
      width: '10%'
    },
    {
      title: 'Action ID',
      dataIndex: 'name',
      key: 'name',
      render: (name, entry) => (
        <Link to={`/action/${entry.id}`}>
          <Button className="jurisdiction__button" icon="edit" type="link">
            {name}
          </Button>
        </Link>
      ),
      width: 150,
      sortOrder: sorterParams.field === 'name' && sortOrder,
      sorter: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 400,
      textWrap: 'ellipsis',
      sortOrder: sorterParams.field === 'description' && sortOrder,
      sorter: true,
    },
    {
      title: 'Review Status',
      dataIndex: 'review_status_label',
      key: 'review_status_id',
      width: 100,
      sortOrder: sorterParams.field === 'review_status_id' && sortOrder,
      sorter: true,
    },
    {
      title: 'Hazards Mitigated',
      dataIndex: 'hazard_options',
      key: 'hazard_options',
      width: 170,
      render: name => renderFromOptions(name, 'hazard_option'),
      sortOrder: sorterParams.field === 'hazard_options' && sortOrder,
      sorter: true,
    },
    {
      title: 'Lead Agency',
      dataIndex: 'lead_agency',
      key: 'lead_agency',
      width: 150,
      sortOrder: sorterParams.field === 'lead_agency' && sortOrder,
      sorter: true,
    },
    {
      title: 'Sources of Funding',
      dataIndex: 'source_of_funding',
      key: 'source_of_funding',
      width: 150,
      sortOrder: sorterParams.field === 'source_of_funding' && sortOrder,
      sorter: true,
    },
    {
      title: 'Priority',
      dataIndex: 'priority_option_id',
      key: 'priority_option_id',
      width: 100,
      render: name => renderFromOptions(name, 'priority_option'),
      sortOrder: sorterParams.field === 'priority_option_id' && sortOrder,
      sorter: true,
    },
    {
      title: 'Mitigation Action Category',
      dataIndex: 'mitigation_option_id',
      key: 'mitigation_option_id',
      width: 200,
      render: name => renderFromOptions(name, 'mitigation_option'),
      // sortOrder: sorterParams.field === 'mitigation_option_id' && sortOrder,
      // sorter: true,
    },
    {
      title: 'CRS Category',
      dataIndex: 'crs_option_id',
      key: 'crs_option_id',
      width: 200,
      render: name => renderFromOptions(name, 'crs_option'),
      sortOrder: sorterParams.field === 'crs_option_id' && sortOrder,
      sorter: true,
    },
    {
      title: 'Estimated Benefit Level',
      dataIndex: 'benefit_option_id',
      key: 'benefit_option_id',
      width: 200,
      render: name => renderFromOptions(name, 'cost_option'),
      sortOrder: sorterParams.field === 'benefit_option_id' && sortOrder,
      sorter: true,
    },
    {
      title: 'Estimated Cost Level',
      dataIndex: 'cost_option_id',
      key: 'cost_option_id',
      width: 200,
      render: name => renderFromOptions(name, 'cost_option'),
      sortOrder: sorterParams.field === 'cost_option_id' && sortOrder,
      sorter: true,
    },
    {
      title: 'Assets/ Structure',
      dataIndex: 'asset_type_id',
      key: 'asset_type_id',
      width: 130,
      render: name => renderFromOptions(name, 'asset_type'),
      // sortOrder: sorterParams.field === 'asset_type_id' && sortOrder,
      // sorter: true,
    },
    {
      title: 'Timeline',
      dataIndex: 'timeline_option_id',
      key: 'timeline_option_id',
      width: 130,
      render: name => renderFromOptions(name, 'timeline_option'),
      // sortOrder: sorterParams.field === 'timeline_option_id' && sortOrder,
      // sorter: true,
    },
    {
      title: 'Last Updated / Reviewed',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: date => moment(date).format('MM/DD/YYYY'),
      sortOrder: sorterParams.field === 'updated_at' && sortOrder,
      sorter: true,
      width: 130
    },
    {
      title: 'Action / History',
      dataIndex: 'class_name',
      key: 'class_name',
      width: 130,
      sorter: (a, b) => a.timeline_option_id > b.timeline_option_id,
      render: name => (name === 'Action' ? (<Tag color="green">Action</Tag>) : (<Tag color="gold">History</Tag>)),
      sortDirections: ['ascend', 'descend'],
    },
  ];

  const [isLoading, toggleLoading] = useState(false);
  const [pager, setPager] = useState({
    total,
    current: currentPage,
    pageSize: SEARCH_TABLE_PER_PAGE,
  });

  useEffect(() => {
    setPager({total});
  }, [total]);

  function handleTableChange(pagination, filters, sorter) {
    params.page = pagination.current;
    params.size = SEARCH_TABLE_PER_PAGE;
    toggleLoading();
    pagination.total = total;

    let {order, field} = sorterParams;

    if (Object.keys(sorter).length) {
      order = sorter.order === 'descend' ? 'desc' : 'asc';
      field = sorter.columnKey;
    } else {
      order = 'asc';
    }

    getSearchItems({
      ...params,
      order: `${field}_${order}`,
      sorterParams: {
        field,
        order
      }
    });

    setPager({...pagination, total});
    toggleLoading();
  }

  return (
    <div className="table__wrapper search-table">
      <Table
        columns={columns}
        dataSource={actions}
        pagination={pager}
        onChange={handleTableChange}
        loading={isLoading}
        scroll={{x: 3000}}
        className="ant-table-fixed"
      />
    </div>
  );
};

ActionsSearchTable.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  availableJurisdictions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  getSearchItems: PropTypes.func.isRequired,
  options: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}).isRequired,
  sorterParams: PropTypes.shape({}).isRequired,
};

export default withRouter(ActionsSearchTable);
