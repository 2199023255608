import {
  PROFILE_GET_REQUEST,
  PROFILE_GET_SUCCESS,
  API_ERROR
} from '../actions/types';

import {createReducer} from '../utils';

const initialState = {
  user: {},
  isFetching: false,
};
const authReducer = createReducer(initialState, {
  [PROFILE_GET_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [PROFILE_GET_SUCCESS]: (state, {user}) => ({...state, user, isFetching: false}),
  [API_ERROR]: state => ({...state, isFetching: false}),
});

export default authReducer;
