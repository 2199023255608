import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import {Table, Button} from 'antd';
import {ACTIONS_TABLE_PER_PAGE} from '../../config/constants';

const ActionsReportTable = (props) => {
  const {
    actions,
    onGetReportItems,
    currentPage,
    params,
    total,
    availableJurisdictions,
    options,
  } = props;

  const renderFromOptions = (name, field) => {
    if (!name) {
      return null;
    }

    const selectedOptions = Array.isArray(name)
      ? name.map(
        item => options[field].find(opt => opt.id === item)
      )
      : options[field].find(opt => opt.id === name);

    if (!selectedOptions) {
      return null;
    }

    return Array.isArray(selectedOptions)
      ? selectedOptions.map(item => item.label).join(', ')
      : selectedOptions.label;
  };

  const jurisdictionNameById = (id) => {
    const jr = availableJurisdictions.find(item => item.id === id)
    return jr ? jr.name : null
  }

  const columns = [
    {
      title: 'Review',
      dataIndex: 'jurisdiction_name',
      key: 'jurisdiction_name',
      render: (id, entry) => (
        <Link to={`/dashboard/jurisdiction/${id}`}>
          <Button className="jurisdiction__button jurisdiction__button--ActionsReportTable-1" icon="edit" type="link">
            {jurisdictionNameById(entry.jurisdiction_id)}
          </Button>
        </Link>
      ),
      width: '20%'
    },
    {
      title: 'Action ID',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      },
      render: (name, entry) => (
        <Link to={`/action/${entry.id}`}>
          <Button className="jurisdiction__button jurisdiction__button--ActionsReportTable-2" icon="edit" type="link">
            {name}
          </Button>
        </Link>
      ),
      sortDirections: ['ascend', 'descend'],
      width: '15%'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (name, entry) => (<span className="clamp-to-3-lines">{entry.description}</span>),
      sorter: (a, b) => {
        if (a.description > b.description) {
          return 1;
        }
        if (a.description < b.description) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '20%'
    },
    {
      title: 'Hazards Mitigated',
      dataIndex: 'hazard_options',
      key: 'hazard_options',
      render: name => renderFromOptions(name, 'hazard_option'),
      sorter: (a, b) => {
        if (a.hazards_mitigated > b.hazards_mitigated) {
          return 1;
        }
        if (a.hazards_mitigated < b.hazards_mitigated) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '20%'
    },
    {
      title: 'Lead Agency',
      dataIndex: 'lead_agency',
      key: 'lead_agency',
      render: (name, entry) => (<span className="clamp-to-3-lines">{entry.lead_agency}</span>),
      sorter: (a, b) => {
        if (a.lead_agency > b.lead_agency) {
          return 1;
        }
        if (a.lead_agency < b.lead_agency) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '15%'
    },
    {
      title: 'Sources of Funding',
      dataIndex: 'source_of_funding',
      key: 'source_of_funding',
      sorter: (a, b) => {
        if (a.source_of_funding > b.source_of_funding) {
          return 1;
        }
        if (a.source_of_funding < b.source_of_funding) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '10%'
    },
    {
      title: 'Priority',
      dataIndex: 'priority_option_id',
      key: 'priority_option_id',
      render: name => renderFromOptions(name, 'priority_option'),
      sorter: (a, b) => {
        if (a.priority > b.priority) {
          return 1;
        }
        if (a.priority < b.priority) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '15%'
    },
    {
      title: 'Mitigation Action Category',
      dataIndex: 'mitigation_option_id',
      key: 'mitigation_option_id',
      render: name => renderFromOptions(name, 'mitigation_option'),
      sorter: (a, b) => {
        if (a.mitigation_action_category > b.mitigation_action_category) {
          return 1;
        }
        if (a.mitigation_action_category < b.mitigation_action_category) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '15%'
    },
    {
      title: 'CRS Category',
      dataIndex: 'crs_option_id',
      key: 'crs_option_id',
      render: name => renderFromOptions(name, 'crs_option'),
      sorter: (a, b) => {
        if (a.crs_category > b.crs_category) {
          return 1;
        }
        if (a.crs_category < b.crs_category) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '15%'
    },
  ];

  const [isLoading, toggleLoading] = useState(false);
  const [pager, setPager] = useState({
    total,
    current: currentPage,
    pageSize: ACTIONS_TABLE_PER_PAGE,
  });

  useEffect(() => {
    setPager({total});
  }, [total]);

  function handleTableChange(pagination) {
    params.page = pagination.current;
    params.size = ACTIONS_TABLE_PER_PAGE;
    params.include_history = false;
    params.condition = 'and';
    toggleLoading();

    onGetReportItems(params);
    setPager({...pagination});
    toggleLoading();
  }

  return (
    <div className="table__wrapper">
      <Table
        columns={columns}
        dataSource={actions}
        pagination={pager}
        onChange={handleTableChange}
        loading={isLoading}
        scroll={{x: 1500}}
      />
    </div>
  );
};

ActionsReportTable.propTypes = {
  availableJurisdictions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentPage: PropTypes.number,
  total: PropTypes.number.isRequired,
  onGetReportItems: PropTypes.func.isRequired,
  options: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}).isRequired,
};

ActionsReportTable.defaultProps = {
  currentPage: 1,
};

export default withRouter(ActionsReportTable);
