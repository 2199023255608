import React from 'react';
import {ModalHint} from '..';

export function labelSpan(label, field, hasTooltip, className) {
  return (
    <span className={className}>
      {label}
      {hasTooltip
        ? (
          <>
            <ModalHint field={field}/>
&nbsp;
          </>
        ) : null}
    </span>
  );
}
