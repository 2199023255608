import {
  API_ERROR,
  APP_SHOW_PROFILE,
  APP_HIDE_PROFILE,
  APP_MESSAGE
} from '../actions/types';

import {createReducer} from '../utils';

const initialState = {
  error: null,
  showProfileForm: false,
  message: null
};
const appReducer = createReducer(initialState, {
  [API_ERROR]: (state, {error}) => ({...state, error}),
  [APP_SHOW_PROFILE]: state => ({...state, showProfileForm: true}),
  [APP_HIDE_PROFILE]: state => ({...state, showProfileForm: false}),
  [APP_MESSAGE]: (state, {message}) => ({...state, message})
});

export default appReducer;
