import React from 'react';
import {Form, Select} from 'antd';
import {shouldDisableField} from '../../../utils/shouldDisableField';
import {ACTION_FORM_FIELDS} from '../../../config/constants';

const {Option} = Select;

// It was bad design decision not to be able to pass “disabled” value from parent. Here we are fixing that.
function isDisabledWithOverride(props, field, passed_via_props) {
  if (passed_via_props === true) return passed_via_props
  if (passed_via_props === false) return (shouldDisableField(props, field)) || false
  return shouldDisableField(props, field)
}

export function SelectInput(props) {
  // const { label, field, optionName, mode, currentAction, options, form: {getFieldDecorator} } = props;
  const {
    label, field, optionName, mode, currentAction, isDisabled, requiredCondition, options, form: {getFieldDecorator}
  } = props;

  return (
    <Form.Item
      label={label}
      labelCol={{span: 8}}
    >
      {
        getFieldDecorator(field, {
          rules: [
            {
              required: requiredCondition,
              validator: (rule, value, callback) => {
                try {
                  if (requiredCondition && !value) {
                    throw new Error(`${ACTION_FORM_FIELDS[field]} is required`);
                  }
                  callback();
                } catch (err) {
                  callback(err);
                }
              }
            }
          ],
          initialValue: currentAction[field]
        })(
          <Select
            allowClear
            disabled={isDisabledWithOverride(props, field, isDisabled)}
            id={field}
            mode={String(mode)}
            optionFilterProp="label"
          >
            {
              options[optionName].map((item) => {
                if (item == null) {
                  return null;
                }
                return (<Option key={item.id} value={item.id} label={item.label}>{item.label}</Option>);
              })
            }
          </Select>
        )
      }
    </Form.Item>
  );
}
