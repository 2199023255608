import React from 'react';
import {Form, InputNumber} from 'antd';
import {shouldDisableField} from '../../../utils/shouldDisableField';
import {labelSpan} from './LabelSpan';

const Number = (props) => {
  const {
    label,
    field,
    isRequired,
    isDecimal,
    hasTooltip,
    placeholder,
    currentAction,
    form: {
      getFieldDecorator
    },
    labelCol,
    inputCol,
  } = props;

  const getStep = () => isDecimal ? '0.01' : '1'
  const disabled = shouldDisableField(props, field)
  const rule_required = () => isRequired && !disabled
  const rule_message = () => rule_required() ? 'A numeric value is required (no commas)' : null

  return (
    <Form.Item
      label={labelSpan(label, field, hasTooltip)}
      labelCol={{span: labelCol || 8}}
      wrapperCol={{span: inputCol || 12}}
    >
      {
        getFieldDecorator(field, {
          rules: [{
            required: rule_required(),
            message: rule_message()
          }],
          initialValue: currentAction[field]
        })(
        <InputNumber
          autoComplete="off"
          disabled={disabled}
          min={0}
          name={field}
          placeholder={placeholder || label} id={field}
          step={getStep()}
          style={{appearance: 'textfield', width: '100%'}}
          type="number"
        />)
      }
    </Form.Item>
  );
};

export default Number;
