import React from 'react';
import PropTypes from 'prop-types';
import {
  Progress,
  Typography,
} from 'antd';
import {ModalHint} from '../index';
import './index.css';

const {Title} = Typography;
const Bars = (props) => {
  const {jurisdictionProgress, title, hintField} = props;
  const totalProgress = jurisdictionProgress.reduce((acc, item) => acc + item.value, 0);

  return (
    <>
      <Title level={4}>
        {title}
        &nbsp;
        {hintField && <ModalHint field={hintField}/>}
      </Title>
      {
        jurisdictionProgress.map(item => (
          <div className="bars__wrapper" key={`key-${item.name}`}>
            <div className="bars__title">
              <span>{item.name}</span>
              <span>{item.value}</span>
            </div>
            <Progress
              percent={(item.value / totalProgress) * 100}
              strokeColor={item.color}
              showInfo={false}
            />
          </div>
        ))
      }
    </>
  );
};

Bars.propTypes = {
  jurisdictionProgress: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  hintField: PropTypes.string,
};

Bars.defaultProps = {
  hintField: null
};

export default Bars;
