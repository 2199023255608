import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Form,
} from 'antd';
import PlanForm from './PlanForm';

const PForm = Form.create()(PlanForm);

const Plan = (props) => {
  const {plan, onSavePlan, role} = props;
  const [isVisible, toggleVisibility] = useState(false);

  function toggleModal() {
    toggleVisibility(!isVisible);
  }

  return (
    <>
      <Button type="danger" icon="form" onClick={toggleModal}>Plan</Button>
      <Modal
        visible={isVisible}
        title="County Name"
        width={900}
        bodyStyle={{
          overflowY: 'auto'
        }}
        onCancel={toggleModal}
        footer={null}
      >
        <PForm plan={plan} onSavePlan={onSavePlan} toggleModal={toggleModal} role={role}/>
      </Modal>
    </>
  );
};

Plan.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  onSavePlan: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired
};

export default Plan;
