import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Table,
  Button,
  Icon,
} from 'antd';
import moment from 'moment';
import {TABLE_VISIBLE_PART, TABLE_PER_PAGE} from '../../config/constants';
import './index.css';

const JurisdictionsTable = (props) => {
  const {
    jurisdictions,
    isScrollable,
    currentJurisdictionId,
  } = props;

  const columns = [
    {
      title: 'Jurisdiction',
      dataIndex: 'name',
      key: 'name',
      render: (name, entry) => (
        <Link to={`/dashboard/jurisdiction/${entry.id}`}>
          <Button className="jurisdiction__button" icon="edit" type="link" disabled={currentJurisdictionId === entry.id}>{name}</Button>
        </Link>
      ),
      sorter: (a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '20%'
    },
    {
      title: 'Review Cycle Open Date',
      dataIndex: 'plan',
      key: 'plan',
      render: plan => (<span>{moment(plan.review_start_date).format('MM/DD/YYYY')}</span>),
      sorter: (a, b) => {
        if (a.review_start_date > b.review_start_date) {
          return 1;
        }
        if (a.review_start_date < b.review_start_date) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '13%'
    },
    {
      title: 'Review Cycle Close Date',
      dataIndex: 'plan',
      key: 'plan2',
      render: plan => (<span>{moment(plan.review_end_date).format('MM/DD/YYYY')}</span>),
      sorter: (a, b) => {
        if (a.review_end_date > b.review_end_date) {
          return 1;
        }
        if (a.review_end_date < b.review_end_date) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '13%'
    },
    {
      title: 'Point of Contact',
      dataIndex: 'contact',
      key: 'contact',
      render: contact => (
        <span>
          {
            contact
              ? `${contact.primary_first_name} ${contact.primary_last_name}, ${contact.alternate_first_name} ${contact.alternate_last_name}`
              : null
          }
        </span>
      ),
      sorter: (a, b) => a.contact && b.contact
        && (a.contact.primary_first_name < b.contact.primary_first_name),
      sortDirections: ['ascend', 'descend'],
      width: '20%'
    },
    {
      title: '# of Actions',
      dataIndex: 'actions_number',
      key: 'actions_number',
      sorter: (a, b) => {
        if (a.actions_number > b.actions_number) {
          return 1;
        }
        if (a.actions_number < b.actions_number) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '10%'
    },
    {
      title: '# Actions the Review is Complete',
      dataIndex: 'completed_review',
      key: 'completed_review',
      sorter: (a, b) => {
        if (a.completed_review > b.completed_review) {
          return 1;
        }
        if (a.completed_review < b.completed_review) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '12%'
    },
    {
      title: '% of Actions the Review is Complete',
      dataIndex: 'completed_review_percentage',
      key: 'completed_review_percentage',
      render: completed => (<span>{`${completed}%`}</span>),
      sorter: (a, b) => {
        if (a.completed_review_percentage > b.completed_review_percentage) {
          return 1;
        }
        if (a.completed_review_percentage < b.completed_review_percentage) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
      width: '12%'
    }
  ];

  return (
    <>
      <div className="status__table-note">
        Click&nbsp;
        <Icon type="edit"/>
        &nbsp;to open the jurisdiction dashboard
      </div>
      <div className="table__wrapper table__wrapper-full">
        <Table
          className="table_full"
          columns={columns}
          dataSource={jurisdictions}
          scroll={{y: isScrollable ? TABLE_VISIBLE_PART : ''}}
          pagination={{pageSize: TABLE_PER_PAGE}}
        />
      </div>
    </>
  );
};

JurisdictionsTable.propTypes = {
  jurisdictions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isScrollable: PropTypes.bool.isRequired,
  currentJurisdictionId: PropTypes.number.isRequired,
};

export default withRouter(JurisdictionsTable);
