import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Spin, Form} from 'antd';
import {getAction, getActionOptions, updateAction} from '../../../actions/actions';
import {ActionForm} from '../../../components';
import './index.css';

function ActionPage(props) {
  const {
    onGetAction,
    onGetActionOptions,
    onUpdateAction,
    currentAction,
    options,
    match: {
      params: {
        id: actionId
      }
    },
    isFetching,
  } = props;

  useEffect(() => {
    onGetActionOptions();
    onGetAction({id: actionId});
  }, [actionId]);

  const ActionFormComponent = Form.create({name: 'action'})(ActionForm);

  return (
    <>
      {
        isFetching || !options
          ? (<div className="spin__wrapper"><Spin size="large"/></div>)
          : (
            <ActionFormComponent
              onUpdateAction={onUpdateAction}
              currentAction={currentAction}
              options={options}
              id={actionId}
            />
          )
      }
    </>
  );
}

ActionPage.propTypes = {
  onGetAction: PropTypes.func.isRequired,
  onGetActionOptions: PropTypes.func.isRequired,
  onUpdateAction: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  currentAction: PropTypes.shape({}),
  options: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired
};

ActionPage.defaultProps = {
  currentAction: {}
};

const mapStateToProps = ({
  actions: {
    isFetching,
    currentAction,
    options,
  }
}) => ({
  isFetching,
  currentAction,
  options,
});

const bindActions = dispatch => ({
  onGetAction: params => dispatch(getAction(params)),
  onGetActionOptions: () => dispatch(getActionOptions()),
  onUpdateAction: params => dispatch(updateAction(params))
});

export default connect(mapStateToProps, bindActions)(ActionPage);
