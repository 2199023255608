import {push} from 'react-router-redux';
import {store} from '../store';

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers[action.type]) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}

export function forwardTo(location) {
  return store.dispatch(push(location));
}
