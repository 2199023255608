import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Layout,
  notification,
  Spin
} from 'antd';
import {
  MainHeader,
  MainFooter,
  MainMenu,
  // Breadcrumbs
} from '../common';

import {
  ProfileForm
} from '..';

import {logoutUser} from '../../actions/auth';
import {toggleProfileForm, showMessage, cleanError} from '../../actions/application';
import {getProfile, saveProfile} from '../../actions/profile';
import {getSettings} from '../../actions/settings';
import './index.css';

const {
  Content,
} = Layout;

function RootContainer(props) {
  const {
    isAuthenticated,
    children,
    onUserLogout,
    user,
    onToggleProfile,
    showProfileForm,
    onGetProfile,
    onSaveProfile,
    onGetSettings,
    profile,
    cleanMessage,
    hideError,
    error,
    message,
    settings,
    settings: {
      isFetching
    },
  } = props;

  useEffect(() => {
    onGetSettings();
    onGetProfile();
  }, []);

  if (!isAuthenticated) {
    return <Redirect to="/"/>;
  }

  if (error) {
    notification.error({
      duration: 3,
      message: error,
      onClose: hideError
    });
  }

  if (message) {
    notification.success({
      message,
      duration: 3,
      onClose: cleanMessage
    });
  }

  return (
    <Layout className="root-container">
      <Layout>
        <MainMenu settings={settings} role={user.role}/>
        <Layout>
          <MainHeader onUserLogout={onUserLogout} user={user} toggleProfile={onToggleProfile}/>
          <Content className="main-wrapper">
            {/* <Breadcrumbs/> */}
            {isFetching
              ? (
                <div className="spin__wrapper"><Spin size="large"/></div>
              )
              : children
            }
          </Content>
        </Layout>
      </Layout>
      <MainFooter/>
      {showProfileForm
        && (
          <ProfileForm
            profile={profile}
            visible={showProfileForm}
            onClose={onToggleProfile}
            getProfile={onGetProfile}
            saveProfile={onSaveProfile}
          />
        )
      }
    </Layout>
  );
}

RootContainer.propTypes = {
  isAuthenticated: PropTypes.bool,
  onUserLogout: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  onToggleProfile: PropTypes.func.isRequired,
  showProfileForm: PropTypes.bool.isRequired,
  onGetProfile: PropTypes.func.isRequired,
  onGetSettings: PropTypes.func.isRequired,
  onSaveProfile: PropTypes.func.isRequired,
  cleanMessage: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  profile: PropTypes.shape({}).isRequired,
  error: PropTypes.oneOfType([() => null, PropTypes.string]),
  message: PropTypes.oneOfType([() => null, PropTypes.string]),
  settings: PropTypes.shape({}).isRequired,
};

RootContainer.defaultProps = {
  isAuthenticated: false,
  error: null,
  message: null,
};

const mapStateToProps = ({
  auth, application, profile, settings
}) => ({
  isAuthenticated: auth.isAuthenticated,
  user: auth.user,
  showProfileForm: application.showProfileForm,
  error: application.error,
  message: application.message,
  profile,
  settings
});

const bindActions = dispatch => ({
  onUserLogout: () => dispatch(logoutUser()),
  onToggleProfile: () => dispatch(toggleProfileForm()),
  onGetProfile: () => dispatch(getProfile()),
  onGetSettings: () => dispatch(getSettings()),
  onSaveProfile: params => dispatch(saveProfile(params)),
  cleanMessage: () => dispatch(showMessage(null)),
  hideError: () => dispatch(cleanError(null)),
});

export default connect(mapStateToProps, bindActions)(RootContainer);
