import React from 'react';
import {Row, Col} from 'antd';
import {RadioInput} from '../common/form-controls/RadioInput';
import {TextInput} from '../common/form-controls/TextInput';
import InputNumber from '../common/form-controls/InputNumber';
import {SelectInput} from '../common/form-controls/SelectInput';
import {TextAreaInput} from '../common/form-controls/TextAreaInput';
import './index.css';

const FieldsCompletedStatus = (props) => {
  const {form: {getFieldValue}, options, currentAction} = props.formProps;

  const grantFundingSource = () => {
    const attr = 'grant_funding_source_id';
    const optionSource = getFieldValue(attr) || currentAction[attr];
    return options.grant_funding_source.find(option => option.id === optionSource)
  };

  const grantFundingSourceLabel = () => {
    const source = grantFundingSource()
    return source ? source.label : null
  }

  const shouldValidate = () => (
    [null, undefined].includes(currentAction.benefit_cost_analysis)
      ? true
      : currentAction.benefit_cost_analysis
  );

  return (
    <>
      <Row>
        <Col span={5}>
          <p className="radio-label ant-form-item-required">Was this project / action grant funded?</p>
        </Col>
        <Col span={6}>
          <RadioInput field="grant_funded" initialValue {...props.formProps}/>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <SelectInput
            optionName="grant_funding_source"
            label="Select the funding source"
            field="grant_funding_source_id"
            {...props.formProps}
          />
        </Col>

        <Col span={12}>
          {
            (['Other'].includes(grantFundingSourceLabel())) &&
            <TextInput
              labelCol={12}
              inputCol={8}
              label="Please specify the funding source"
              field="other_funding_source"
              isRequired
              placeholder="Funding Source"
              {...props.formProps}
            />
          }
          {
            (['State grant', 'Local grant', 'Private grant'].includes(grantFundingSourceLabel())) &&
            <TextInput
              labelCol={12}
              inputCol={8}
              label="Please specify the grant name"
              field="grant_name"
              isRequired
              placeholder="Grant Name"
              {...props.formProps}
            />
          }
        </Col>
      </Row>

      <div className="m-20-block">
        <Row>
          <Col span={9}>
            <p className="radio-label ant-form-item-required">Did you submit a benefit cost analysis (BCA) when applying for the grant?</p>
          </Col>
          <Col span={6} className="radio-column">
            <RadioInput field="benefit_cost_analysis" initialValue={shouldValidate()} {...props.formProps}/>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <InputNumber
              label="What was the ratio?"
              isRequired={shouldValidate()}
              field="bca_ratio"
              placeholder="Ratio"
              isDecimal
              {...props.formProps}
            />

          </Col>

          <Col span={12}/>
        </Row>
        <Row>
          <Col span={12}>
            <InputNumber
              label="Provide the total benefits&nbsp;($)"
              isRequired={shouldValidate()}
              field="bca_total_benefits"
              placeholder="Total Benefits"
              isDecimal
              {...props.formProps}
            />

          </Col>

          <Col span={12}/>
        </Row>
        <Row>
          <Col span={12}>
            <InputNumber
              label="Provide the final cost&nbsp;($)"
              isRequired={shouldValidate()}
              field="bca_final_cost"
              placeholder="Final Cost"
              isDecimal
              {...props.formProps}
            />

          </Col>

          <Col span={12}/>
        </Row>
      </div>

      <Row>
        <Col span={24}>
          <TextAreaInput
            label="What is the project / action’s level of protection?"
            field="level_protection"
            {...props.formProps}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextAreaInput
            label={'Please describe the losses avoided by implementing the project '
            + '(e.g. physical damages, loss of function or service, environmental, economic, social)'}
            field="losses_avoided"
            {...props.formProps}
          />
        </Col>
      </Row>
    </>
  );
};

export default FieldsCompletedStatus;
