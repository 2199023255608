import React from 'react';
import PropTypes from 'prop-types';

const HTMLContent = (props) => {
  const {content} = props;

  return (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{__html: content}}/>
  );
};

HTMLContent.propTypes = {
  content: PropTypes.string.isRequired
};

export default HTMLContent;
