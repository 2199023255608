import React from 'react';
import {Form, Input} from 'antd';
import {ACTION_FORM_FIELDS} from '../../../config/constants';
import {shouldDisableField} from '../../../utils/shouldDisableField';
import {labelSpan} from './LabelSpan';

function checkNumber(n) {return /^-?[\d.]+(?:e-?\d+)?$/.test(n);}

export function TextInput(props) {
  // const { label, field, isRequired, hasTooltip, placeholder, currentAction, form: {getFieldDecorator}, labelCol, inputCol } = props;
  const {
    isNumber, label, field, isRequired, requiredCondition, hasTooltip, placeholder, currentAction, form: {getFieldDecorator}, labelCol, inputCol
  } = props;

  return (
    <Form.Item
      label={labelSpan(label, field, hasTooltip)}
      labelCol={{span: labelCol || 8}}
      wrapperCol={{span: inputCol || 12}}
    >
      {
        getFieldDecorator(field, {
          rules: [
            {
              required: requiredCondition || isRequired,
              validator: (rule, value, callback) => {
                try {
                  if ((requiredCondition || isRequired) && (isNumber && !checkNumber(value))) {
                    throw new Error('Numeric values are required (no commas)');
                  }
                  if ((requiredCondition || isRequired) && !value) {
                    throw new Error(`${ACTION_FORM_FIELDS[field]} is required`);
                  }
                  callback();
                } catch (err) {
                  callback(err);
                }
              }
            }
          ],
          initialValue: currentAction[field]
        })(<Input placeholder={placeholder || label} id={field} name={field} disabled={shouldDisableField(props, field)} autoComplete="off"/>)
      }
    </Form.Item>
  );
}
