import React, {Component} from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
} from 'antd';
import './index.css';
import PropTypes from 'prop-types';
import {InputMask} from '../../common';
import {MODAL_FORM_LAYOUT, PHONE_PATTERN} from '../../../config/constants';

export default class PointOfContactForm extends Component {
  static propTypes = {
    form: PropTypes.shape({}).isRequired,
    contact: PropTypes.shape({}).isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSaveContact: PropTypes.func.isRequired,
    jurisdictionId: PropTypes.number.isRequired
  }

  state = {
    loading: false
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      toggleModal,
      onSaveContact,
      form: {validateFields},
      jurisdictionId
    } = this.props;

    validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }

      this.setState({loading: true});

      await onSaveContact({
        jurisdiction_id: jurisdictionId,
        ...fieldsValue
      });
      this.setState({loading: false});
      toggleModal();
    });
  }

  renderPrimaryFields = () => {
    const {form: {getFieldDecorator}, contact} = this.props;

    return (
      <>
        <Row>
          <Col span={12}>
            <Form.Item label="First Name">
              {getFieldDecorator('primary_first_name', {
                initialValue: contact ? contact.primary_first_name : '',
              })(<Input placeholder="Please enter First Name"/>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Name">
              {getFieldDecorator('primary_last_name', {
                initialValue: contact ? contact.primary_last_name : '',
              })(<Input placeholder="Please enter Last Name"/>)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="E-mail">
              {getFieldDecorator('primary_email', {
                initialValue: contact ? contact.primary_email : '',
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ],
              })(<Input/>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone Number">
              {
                getFieldDecorator('primary_phone', {
                  initialValue: contact ? contact.primary_phone : '',
                  rules: [
                    {
                      pattern: PHONE_PATTERN,
                      message: 'Please enter a valid phone number'
                    }
                  ]
                })(
                  <InputMask
                    className="profile-form__item_field"
                    mask="(999) 999-9999"
                    placeholder="Please enter your Phone Number"
                  />
                )
              }
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Title">
              {getFieldDecorator('primary_title', {
                initialValue: contact ? contact.primary_title : '',
              })(<Input placeholder="Enter Title"/>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Agency">
              {getFieldDecorator('primary_agency', {
                initialValue: contact ? contact.primary_agency : '',
              })(<Input placeholder="Enter Agency"/>)}
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }

  renderAlternativeForm = () => {
    const {form: {getFieldDecorator}, contact} = this.props;

    return (
      <>
        <Row>
          <Col span={12}>
            <Form.Item label="First Name">
              {getFieldDecorator('alternate_first_name', {
                initialValue: contact ? contact.alternate_first_name : '',
              })(<Input placeholder="Please enter First Name"/>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Name">
              {getFieldDecorator('alternate_last_name', {
                initialValue: contact ? contact.alternate_last_name : '',
              })(<Input placeholder="Please enter Last Name"/>)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="E-mail">
              {getFieldDecorator('alternate_email', {
                initialValue: contact ? contact.alternate_email : '',
              })(<Input/>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone Number">
              {
                getFieldDecorator('alternate_phone', {
                  initialValue: contact ? contact.alternate_phone : '',
                  rules: [
                    {
                      pattern: PHONE_PATTERN,
                      message: 'Please enter a valid phone number'
                    }
                  ]
                })(
                  <InputMask
                    className="profile-form__item_field"
                    mask="(999) 999-9999"
                    placeholder="Please enter your Phone Number"
                  />
                )
              }
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Title">
              {getFieldDecorator('alternate_title', {
                initialValue: contact ? contact.alternate_title : '',
              })(<Input placeholder="Enter Title"/>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Agency">
              {getFieldDecorator('alternate_agency', {
                initialValue: contact ? contact.alternate_agency : '',
              })(<Input placeholder="Enter Agency"/>)}
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {loading} = this.state;
    const {toggleModal} = this.props;

    return (
      <Form {...MODAL_FORM_LAYOUT} onSubmit={this.handleSubmit} className="poc-form">
        <div className="form__section">
          <p>Primary Point of Contact</p>
          {this.renderPrimaryFields()}
        </div>
        <div className="form__section">
          <p>Alternative Point of Contact</p>
          {this.renderAlternativeForm()}
        </div>
        <div className="ant-modal-footer">
          <Button key="close" onClick={toggleModal}>
            Close
          </Button>
          <Button
            key="save"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </Form>
    );
  }
}