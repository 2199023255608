import {
  ACTIONS_GET_REQUEST,
  ACTIONS_GET_SUCCESS,
  ACTION_GET_REQUEST,
  ACTION_GET_SUCCESS,
  ACTION_GET_OPTIONS_SUCCESS,
  ACTIONS_UPDATE_REQUEST,
  ACTION_UPDATE_SUCCESS,
  ACTION_CREATE_REQUEST,
  ACTION_CREATE_SUCCESS,
  RESET_CURRENT_ACTION,
  ACTION_SAVE_FORM,
  API_ERROR
} from '../actions/types';

import {createReducer} from '../utils';

const initialState = {
  items: [],
  currentPage: 1,
  isFetching: false,
  currentAction: {},
  sorterParams: {
    field: 'name',
    order: 'asc',
  }
};

const actionsReducer = createReducer(initialState, {
  [ACTIONS_GET_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [ACTIONS_GET_SUCCESS]: (state, {
    items,
    total,
    currentPage,
    sorterParams
  }) => ({
    ...state,
    items,
    currentPage,
    total,
    sorterParams,
    isFetching: false
  }),
  [ACTION_GET_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [ACTION_GET_SUCCESS]: (state, {action}) => ({
    ...state,
    isFetching: false,
    currentAction: action,
  }),
  [ACTION_GET_OPTIONS_SUCCESS]: (state, {options}) => ({
    ...state,
    // isFetching: false,
    options,
  }),
  [ACTIONS_UPDATE_REQUEST]: (state, {action}) => ({
    ...state,
    isFetching: true,
    action,
  }),
  [ACTION_UPDATE_SUCCESS]: (state, {currentAction}) => ({
    ...state,
    isFetching: false,
    currentAction,
  }),
  [ACTION_CREATE_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [ACTION_CREATE_SUCCESS]: (state, {currentAction}) => ({
    ...state,
    isFetching: false,
    currentAction,
  }),
  [RESET_CURRENT_ACTION]: (state, {jurisdiction_name}) => ({
    ...state,
    currentAction: {
      jurisdiction_name
    }
  }),
  [ACTION_SAVE_FORM]: (state, {currentAction}) => ({
    ...state,
    currentAction,
  }),
  [API_ERROR]: state => ({...state, isFetching: false}),
});

export default actionsReducer;
