import React, {Component} from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Collapse,
  Radio,
  DatePicker
} from 'antd';
import './index.css';
import PropTypes from 'prop-types';
import {ModalHint} from '../common';
import {
  SEARCH_FORM_LAYOUT,
  SEARCH_TABLE_PER_PAGE,
  SEARCH_RADIO,
  DATE_FORMAT,
} from '../../config/constants';

const {Option} = Select;
const {Panel} = Collapse;

export default class SearchForm extends Component {
  static propTypes = {
    form: PropTypes.shape({}).isRequired,
    options: PropTypes.shape({}).isRequired,
    params: PropTypes.shape({}).isRequired,
    onGetSearchJurisdictions: PropTypes.func.isRequired,
    onGetSearchActions: PropTypes.func.isRequired,
    getSearchItems: PropTypes.func.isRequired,
    onGenerateExcel: PropTypes.func.isRequired,
    areParamsFetching: PropTypes.bool.isRequired,
    hasActions: PropTypes.bool.isRequired,
    isSearchFetching: PropTypes.bool.isRequired,
    isExcelFetching: PropTypes.bool.isRequired,
    sorterParams: PropTypes.shape({}).isRequired,
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      getSearchItems,
      form: {validateFields},
      sorterParams,
    } = this.props;

    validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }

      fieldsValue.size = SEARCH_TABLE_PER_PAGE;
      fieldsValue.page = 1;

      getSearchItems({...fieldsValue, sorterParams});
    });
  }

  handlePlanChange = () => {
    const {onGetSearchJurisdictions, form: {getFieldsValue}} = this.props;
    const params = getFieldsValue();

    onGetSearchJurisdictions({ids: params.plan_ids, params});
  }

  handlePlanRemove = (removedItem) => {
    const {onGetSearchJurisdictions, form: {getFieldsValue}} = this.props;
    const params = getFieldsValue();

    params.plan_ids.splice(params.plan_ids.indexOf(removedItem), 1);
    params.jurisdiction_id = [];

    if (params.plan_ids.length) {
      onGetSearchJurisdictions({ids: params.plan_ids, params});
    } else {
      onGetSearchJurisdictions({params});
    }
  }

  handleJurisdictionChange = () => {
    const {onGetSearchActions, form: {getFieldsValue}} = this.props;
    const params = getFieldsValue();

    onGetSearchActions({ids: params.jurisdiction_id, params});
  }

  handleJurisdictionRemove = (removedItem) => {
    const {onGetSearchActions, form: {getFieldsValue}} = this.props;
    const params = getFieldsValue();

    params.jurisdiction_id.splice(params.jurisdiction_id.indexOf(removedItem), 1);
    params.action_id = [];

    if (params.jurisdiction_id.length) {
      onGetSearchActions({ids: params.plan_ids, params});
    } else {
      onGetSearchActions({params});
    }
  }

  renderPlansSelect = (label, field, optionName) => {
    const {options, form: {getFieldDecorator}, params} = this.props;

    return (
      <Form.Item label={label}>
        {
          getFieldDecorator(field, {
            initialValue: params[field]
          })(
            <Select
              id={field}
              mode="multiple"
              onSelect={this.handlePlanChange}
              onDeselect={this.handlePlanRemove}
              optionFilterProp="label"
              showArrow
            >
              {
                options[optionName].map(item => (
                  <Option value={item.id} label={item.label}>{item.label || item.name}</Option>
                ))
              }
            </Select>
          )
        }
      </Form.Item>
    );
  }

  renderJurisdictionsSelect = (label, field, optionName) => {
    const {options, form: {getFieldDecorator}, params} = this.props;

    return (
      <Form.Item
        label={label}
        extra="To obtain results for all jurisdictions in the plan, leave the “Jurisdiction” field blank"
      >
        {
          getFieldDecorator(field, {
            initialValue: params[field]
          })(
            <Select
              id={field}
              mode="multiple"
              onSelect={this.handleJurisdictionChange}
              onDeselect={this.handleJurisdictionRemove}
              optionFilterProp="label"
              showArrow
            >
              {
                options[optionName].map(item => (
                  <Option value={item.id} label={item.label}>{item.label || item.name}</Option>
                ))
              }
            </Select>
          )
        }
      </Form.Item>
    );
  }

  renderSelect = (label, field, optionName, hasTooltip) => {
    const {options, form: {getFieldDecorator}, params} = this.props;

    return (
      <Form.Item label={
        (
          <span>
            {label}
            {
              hasTooltip
                ? (
                  <>
                    &nbsp;
                    <ModalHint field={optionName}/>
                    &nbsp;
                  </>
                )
                : null
            }
          </span>
        )}
      >
        {
          getFieldDecorator(field, {
            initialValue: params[field]
          })(
            <Select id={field} mode="multiple" onSelect={this.handleSelectChange} showArrow optionFilterProp="label">
              {
                options[optionName].map(item => (
                  <Option value={item.id} label={item.label || item.name}>
                    {item.label || item.name}
                  </Option>
                ))
              }
            </Select>
          )
        }
      </Form.Item>
    );
  }

  renderInput = (label, field, tooltip) => {
    const {form: {getFieldDecorator}, params} = this.props;

    return (
      <Form.Item
        label={label}
        extra={tooltip}
      >
        {
          getFieldDecorator(field, {
            initialValue: params[field]
          })(<Input placeholder={label} name={field}/>)
        }
      </Form.Item>
    );
  }

  renderRadio = (label, field, hasTooltip) => {
    const {form: {getFieldDecorator}, params} = this.props;

    return (
      <Form.Item label={
        (
          <span>
            {label}
            {
              hasTooltip
                ? (
                  <>
                    &nbsp;
                    <ModalHint field={field}/>
                    &nbsp;
                  </>
                )
                : null
            }
          </span>
        )}
      >
        {getFieldDecorator(field, {
          initialValue: params[field]
        })(
          <Radio.Group>
            {
              SEARCH_RADIO[field].map(item => (<Radio value={item.id}>{item.name}</Radio>))
            }
          </Radio.Group>
        )}
      </Form.Item>
    );
  }

  renderDate = (label, field) => {
    const {form: {getFieldDecorator}, params} = this.props;

    return (
      <Form.Item label={label}>
        {
          getFieldDecorator(field, {
            initialValue: params[field]
          })(<DatePicker name={field} format={DATE_FORMAT}/>)
        }
      </Form.Item>
    );
  }

  renderForm = () => {
    const {
      isExcelFetching,
      isSearchFetching,
      areParamsFetching,
      hasActions,
      onGenerateExcel,
      params
    } = this.props;

    return (
      <>
        <Row>
          <Col span={12}>
            {this.renderPlansSelect('Plans', 'plan_ids', 'plans')}
          </Col>
          <Col span={12}>
            {this.renderRadio('Include filters', 'condition', true)}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {this.renderJurisdictionsSelect('Jurisdiction', 'jurisdiction_id', 'jurisdictions')}
          </Col>
          <Col span={12}>
            {this.renderSelect('Hazard(s) Mitigated', 'hazard_options_ids', 'hazard_option', true)}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {this.renderSelect('CRS Category', 'crs_option_id', 'crs_option', true)}
          </Col>
          <Col span={12}>
            {this.renderSelect('Mitigation Action Category', 'mitigation_option_id', 'mitigation_option', true)}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {this.renderSelect('Updated Action Status', 'action_status_id', 'action_status')}
          </Col>
          <Col span={12}>
            {this.renderSelect('Review Status', 'review_status_id', 'review_status')}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {this.renderInput('Search Text', 'text', 'Search text in lead/supporting agency, source of funding and description')}
          </Col>
          <Col span={12}>
            {this.renderSelect('Priority', 'priority_option_id', 'priority_option')}
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            {this.renderDate('Start Date:', 'start_date')}
          </Col>
          <Col span={6}>
            {this.renderDate('End Date:', 'end_date')}
          </Col>
          <Col span={12}>
            {this.renderSelect('Action ID', 'action_id', 'actions')}
          </Col>
        </Row>
        {this.renderMoreFields()}
        <Row>
          <Col span={12}>
            {
              hasActions
                ? (
                  <Button
                    type="primary"
                    onClick={() => {onGenerateExcel(params);}}
                    loading={isExcelFetching || areParamsFetching}
                  >
                    Export to Excel
                  </Button>
                )
                : null
            }
          </Col>
          <Col span={12} className="ta-r">
            <Button
              type="primary"
              htmlType="submit"
              loading={isSearchFetching || areParamsFetching}
            >
              Search
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderMoreFields = () => (
    <Collapse className="search__collapse">
      <Panel header="Show More Fields" key="more">
        <Row>
          <Col span={12}>
            {this.renderDate('Plan Approval Date:', 'plan_approval_date')}
          </Col>
          <Col span={12}>
            {this.renderRadio('Include History:', 'include_history')}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {this.renderSelect('Estimated Benefit Level', 'estimated_benfit_level_id', 'cost_option')}
          </Col>
          <Col span={12}>
            {this.renderSelect('Estimated Cost Level', 'estimated_cost_level_id', 'cost_option')}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {this.renderInput('Previous Action Status', 'previous_action_status')}
          </Col>
          <Col span={12}>
            {this.renderSelect('Assets/Structure', 'asset_type_id', 'asset_type')}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {this.renderSelect('Timeline', 'timeline_option_id', 'timeline_option')}
          </Col>
          <Col span={12}/>
        </Row>
      </Panel>
    </Collapse>
  )

  render() {
    return (
      <>
        <Form {...SEARCH_FORM_LAYOUT} onSubmit={this.handleSubmit} className="search__form">
          <div className="form__section">
            {this.renderForm()}
          </div>
        </Form>
      </>
    );
  }
}
