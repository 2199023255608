import React, {Component} from 'react';
import {
  Form, Row, Col, Button, Typography, Collapse, Table
} from 'antd';
import {withRouter, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {HTMLContent} from '../common';
import FieldsCompletedStatus from './FieldsCompletedStatus';
import ActionChangesTable from '../ActionChangesTable/index.jsx';
import {ACTION_FORM_LAYOUT, ACTION_NOTE, ACTION_ID_NOTE} from '../../config/constants';
import {TextInput} from '../common/form-controls/TextInput';
import {SelectValidated} from '../common/form-controls/SelectValidated';
import {SelectInput} from '../common/form-controls/SelectInput';
import {TextAreaInput} from '../common/form-controls/TextAreaInput';
import {ACTION_FORM_FIELDS} from '../../config/constants';
import './index.css';

const {Title} = Typography;
const {Panel} = Collapse;
const columns = [
  { title: 'Modify Date', dataIndex: 'modify_date', key: 'modify_date', },
  { title: 'Reviewer',    dataIndex: 'reviewer',    key: 'reviewer', },
  { title: 'Change From', dataIndex: 'change_from', key: 'change_from', },
  { title: 'Change To',   dataIndex: 'change_to',   key: 'change_to', },
];

class ActionForm extends Component {
  static propTypes = {
    currentAction: PropTypes.shape({}).isRequired,
    form: PropTypes.shape({}).isRequired,
    id: PropTypes.number.isRequired,
    onUpdateAction: PropTypes.func.isRequired,
    onCreateAction: PropTypes.func.isRequired,
    options: PropTypes.shape({}).isRequired,
    create: PropTypes.bool.isRequired,
  }

  state = {
    loading: false,
    review_status: null
  };

  handleReviewStateCompleted = (e) => {
    e.preventDefault();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: {validateFields},
      currentAction,
      onUpdateAction,
      onCreateAction,
      create,
      id,
    } = this.props;
    const {review_status} = this.state;

    currentAction.grant_name = null;
    currentAction.other_funding_source = null;

    validateFields(async (err, fieldsValue) => {
      if (err) {
        console.log(err);
        return;
      }

      Object.keys(currentAction).forEach((key) => {
        if (!fieldsValue[key] && fieldsValue[key] !== '') {
          fieldsValue[key] = currentAction[key];
        }
      });
      fieldsValue.previous_action_status_id = 1;
      fieldsValue.review_status = review_status;
      console.log('fieldsValue');
      console.log(fieldsValue);
      this.setState({loading: true});

      if (create) {
        onCreateAction({jurisdictionId: id, fieldsValue});
      } else {
        onUpdateAction({id, fieldsValue});
      }

      this.setState({loading: false});
    });
  }

  setReviewStatus = (review_status) => {
    this.setState({review_status});
  }

  renderFieldsCompletedStatus = () => (
    <FieldsCompletedStatus formProps={this.props}/>
  )

  statusCompleted = status => status && status.label === 'Completed'

  renderForm = () => (
    <>
      <Row>
        <Col span={24}>
          <h4 className="action-form__step-text">
            <HTMLContent content={ACTION_NOTE}/>
          </h4>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Title level={4} className="action-form__step-text">Step 1: Enter your name as the “{ACTION_FORM_FIELDS['reviewer']}” (then step 2 will be enabled for selection).</Title>
        </Col>
        <Col span={4}/>
        <Col span={10}>
          <Title level={4} className="action-form__step-text">Step 2: Update the “{ACTION_FORM_FIELDS['action_status_id']}” field. Please continue to review and update all other fields, as needed.</Title>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <TextInput label="Reviewer" field="reviewer" isRequired {...this.props}/>
        </Col>
        <Col span={12}>
          <SelectValidated
            label="Action Status"
            field="action_status_id"
            optionName="action_status"
            {...this.props}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}/>
        <Col span={12}>
          <SelectInput
            optionName="action_status"
            label="Previous Action Status"
            field="previous_action_status_id"
            {...this.props}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextAreaInput label="Project / Action Status Comments" field="comments" {...this.props}/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextAreaInput label={ACTION_FORM_FIELDS['description']} field="description" isRequired {...this.props}/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextAreaInput label="Specific Problem Mitigated" field="specific_problem_mitigated" {...this.props}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <TextInput label="Goals and / or Objectives" field="goals" hasTooltip {...this.props}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <TextInput label="Lead Agency" field="lead_agency" isRequired {...this.props}/>
        </Col>
        <Col span={12}>
          <SelectValidated
            label="Priority"
            field="priority_option_id"
            optionName="priority_option"
            {...this.props}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <TextInput label="Support Agency" field="support_agency" {...this.props}/>
        </Col>
        <Col span={12}>
          <SelectValidated
            label={ACTION_FORM_FIELDS['hazard_options']}
            field="hazard_options"
            optionName="hazard_option"
            mode="multiple"
            {...this.props}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <SelectValidated
            label={ACTION_FORM_FIELDS['benefit_option_id']}
            field="benefit_option_id"
            optionName="cost_option"
            {...this.props}
          />
        </Col>
        <Col span={12}>
          <SelectValidated
            label="Cost"
            field="cost_option_id"
            optionName="cost_option"
            {...this.props}
          />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <TextInput label="Describe benefits" field="benefit_note" {...this.props}/>
        </Col>
        <Col span={12}>
          <TextInput label="Detail estimated cost" field="cost_note" {...this.props}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <TextInput label="Source of Funding" field="source_of_funding" {...this.props}/>
        </Col>
        <Col span={12}>
          <SelectValidated
            label={ACTION_FORM_FIELDS['timeline_option_id']}
            field="timeline_option_id"
            optionName="timeline_option"
            {...this.props}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <SelectValidated
            label={ACTION_FORM_FIELDS['asset_type_id']}
            field="asset_type_id"
            optionName="asset_type"
            {...this.props}
          />
        </Col>
        <Col span={12}>
          <TextInput label="Timeline Comments" field="timeline_comment" {...this.props}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <SelectInput
            optionName="mitigation_option"
            label="Mitigation Action Category"
            field="mitigation_option_id"
            {...this.props}
          />
        </Col>
        <Col span={12}>
          <SelectInput
            optionName="crs_option"
            label="CRS Category"
            field="crs_option_id"
            {...this.props}
          />
        </Col>
      </Row>

      <div className="new-questions-block">
        {this.statusCompleted(this.currentActionStatus()) ? this.renderFieldsCompletedStatus() : ''}

        <Row className="m-t-10">
          <Col span={24} className="p-l-r-20">
            <b>
              <p className="title-bold">
                Project Location:&nbsp;
              </p>
              If the action takes place in a specific location or site
               (i.e., project, not planning activity or capability),
              click on the following link to open a new window and
               place the project location on this map:&nbsp;
              <a href="https://survey123.arcgis.com/share/629a066016774456919da1aaccca848f" target="_blank" rel="noopener noreferrer">
                <u>MAP LOCATION</u>
              </a>
              . The location only needs to be identified one time for
               each Action ID (not each time you review the action).
              <p className="m-t-5 m-b-30">
                <i>
                  *Don’t forget to Save & Submit your work in the BATool
                  <sup>SM</sup>
                  {' '}
                  after clicking on the link.
                </i>
              </p>
            </b>
          </Col>
        </Row>
      </div>
    </>
  );

  currentActionStatus = () => {
    const {form: {getFieldValue}, options, currentAction} = this.props;
    const fieldName = 'action_status_id';
    const formActionStatusId = getFieldValue(fieldName) || currentAction[fieldName];

    return options.action_status
      .find(option => option.id === formActionStatusId);
  }

  getActionStatus = () => {
    const {currentAction: {review_status_id}, options} = this.props;

    const reviewStatus = options.review_status.find(item => item.id === review_status_id);

    switch (reviewStatus && reviewStatus.label.trim()) {
      case 'Review Not Started':
      case '-':
        return <span className="review-message review-message_not-started">Review of this Action has not started</span>;
      case 'Review In Progress':
        return <span className="review-message review-message_in-progress">Review of this Action is in progress</span>;
      case 'Review Complete':
        return <span className="review-message review-message_complete">Review of this Action is complete</span>;
      default:
        return null;
    }
  };

  renderButtonsAndHistory = () => {
    const {
      currentAction,
      create,
      currentAction: {
        history_changes,
        prev_action_id,
        next_action_id
      },
    } = this.props;

    const {loading} = this.state;
    return (
      !create
        ? (
          <>
            <Row className="action-form__buttons">
              <Col span={5}>
                <Link to={`/action/${prev_action_id}`}>
                  <Button loading={loading} type="primary">Prev Action</Button>
                </Link>
              </Col>
              <Col span={15}>
                <TextInput label="Action ID" field="name" isRequired {...this.props}/>
              </Col>
              <Col span={4} className="ta-r">
                <Link to={`/action/${next_action_id}`}>
                  <Button loading={loading} type="primary">Next Action</Button>
                </Link>
              </Col>
            </Row>
            <Row className="action-form__buttons">
              <Col span={8}/>
              <Col span={12}>{this.getActionStatus()}
              </Col>
              <Col span={4} className="ta-r">
                <Link to={`/create/action/${currentAction.jurisdiction_id}`}>
                  <Button type="primary"><strong>Add New Action</strong></Button>
                </Link>
              </Col>
            </Row>
            <Collapse className="action-form__collapse">
              <Panel header="View Update History" key="hist">
                <ActionChangesTable changes={history_changes} />
              </Panel>
            </Collapse>
          </>
        )
        : (
          <Row className="action-form__buttons">
            <Col span={12}>
              <h4 className="action-form__step-text">
                <HTMLContent content={ACTION_ID_NOTE}/>
              </h4>
              <TextInput label="Action ID" field="name" isRequired {...this.props}/>
            </Col>
            <Col span={12}/>
          </Row>
        )
    );
  }

  renderFooterButtons = () => {
    const {loading} = this.state;

    return (
      <div className="ant-modal-footer">
        <Row>
          <Col span={12} className="ta-l">
            <Button
              key="save"
              htmlType="submit"
              loading={loading}
              onClick={() => {this.setReviewStatus('IN_PROGRESS');}}
            >
              Save Draft
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              loading={loading}
              onClick={() => {this.setReviewStatus('COMPLETE');}}
            >
              Save & Submit
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const {currentAction: {jurisdiction_name}} = this.props;
    return (
      <>
        <h2 className="jurisdiction__name">{jurisdiction_name}</h2>
        { this.renderButtonsAndHistory() }
        <Form {...ACTION_FORM_LAYOUT} onSubmit={this.handleSubmit} className="form_custom" autoComplete="off">
          <div className="form__section">
            { this.renderForm() }
          </div>
          { this.renderFooterButtons() }
        </Form>
      </>
    );
  }
}

export default withRouter(ActionForm);
