import React from 'react';
import {Form, Input} from 'antd';
import {shouldDisableField} from '../../../utils/shouldDisableField';
import {ACTION_FORM_FIELDS} from '../../../config/constants';

const {TextArea} = Input;

export function TextAreaInput(props) {
  const {
    label, field, isRequired, className, currentAction, form: {getFieldDecorator}
  } = props;

  return (
    <Form.Item label={label} labelCol={{span: 4}} wrapperCol={{span: 18}}>
      {
        getFieldDecorator(field, {
          rules: [
            {
              required: isRequired,
              message: `${ACTION_FORM_FIELDS[field]} is required`
            }
          ],
          initialValue: currentAction[field]
        })(<TextArea className={className} rows={4} name={field} disabled={shouldDisableField(props, field)} autoComplete="off"/>)
      }
    </Form.Item>
  );
}
