import {
  CONTENT_REQUEST,
  ABOUT_SUCCESS,
  RESOURCES_SUCCESS,
} from '../actions/types';

import {createReducer} from '../utils';

const initialState = {
  isFetching: false,
  about: {
    title: '',
    content: ''
  },
  resources: {
    title: '',
    content: ''
  }
};

const aboutReducer = createReducer(initialState, {
  [CONTENT_REQUEST]: state => ({
    ...state,
    isFetching: true
  }),
  [ABOUT_SUCCESS]: (state, {about}) => ({
    ...state,
    isFetching: false,
    about
  }),
  [RESOURCES_SUCCESS]: (state, {resources}) => ({
    ...state,
    isFetching: false,
    resources
  }),
});

export default aboutReducer;
