import React from 'react';
import './index.css';

function ExplicitNull(props) {
  return (props.value === null) ? <code>null</code> : props.value
}

function explicit_array(value) {
  return Array.isArray(value) ? value.join(', ') : value
}

// The array column containing IDs is called 'hazard_options' (instead of 'hazard_option_ids'),
// and now to pass the labels we had to add '_labels' in Action::Changes, and here to remove it
function adapt_attr_name(name) {
  return name === 'hazard_options_labels' ? 'hazard_options' : name
}

export default function AttributeChangesTable(props) {
  const attributes_to_omit = [
    'action_status_id',
    'asset_type_id',
    'benefit_option_id',
    'cost_option_id',
    'crs_option_id',
    'grant_funding_source_id',
    'mitigation_option_id',
    'previous_action_status_id',
    'previous_priority_option_id',
    'priority_option_id',
    'review_status_id',
    'timeline_option_id',
    'hazard_options'
  ]

  const data = Object.entries(props.change.changes)
                     .map(arr => ({ attr: arr[0], old_value: arr[1][0], new_value: arr[1][1] }))
                     .filter(data => !attributes_to_omit.includes(data.attr))

  return (
    <table className="attr-changes__table">
      <thead>
        <tr>
          <th scope="col" className="attr-changes__th-col">Attribute</th>
          <th scope="col" className="attr-changes__th-col">From</th>
          <th scope="col" className="attr-changes__th-col"></th>
          <th scope="col" className="attr-changes__th-col">To</th>
        </tr>
      </thead>
      <tbody>
        {data.map(attr_change => (
          <tr>
            <th scope="row" className="attr-changes__th-row">“{adapt_attr_name(attr_change.attr)}”</th>
            <td scope="row" className="attr-changes__td" data-change="from"><ExplicitNull value={explicit_array(attr_change.old_value)} /></td>
            <td scope="row" className="attr-changes__td attr-changes__td--arrow">→</td>
            <td scope="row" className="attr-changes__td" data-change="to"><ExplicitNull value={explicit_array(attr_change.new_value)} /></td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
