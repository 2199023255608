export default {
  dashboard: {
    title: 'Dashboard',
    icon: 'area-chart',
    path: '/dashboard'
  },
  plan: {
    title: 'Plan Actions',
    icon: 'check-square',
    path: '/plan-actions'
  },
  search: {
    title: 'Search',
    icon: 'file-search',
    path: '/search'
  },
  reports: {
    title: 'Reports',
    icon: 'fund',
    path: '/reports'
  },
  resources: {
    title: 'Resources',
    icon: 'snippets',
    path: '/resources'
  },
  about: {
    title: 'About',
    icon: 'file-exclamation',
    path: '/about'
  },
  contact: {
    title: 'Contact Us',
    icon: 'mail',
    path: null,
    href: 'mailto:bspencer@ccfd.org?bcc=BAToolHelp@tetratech.com&Subject=Contact%20Us%20regarding%20HMP%20Progress%20Reporting%20Tool'
  }
};
