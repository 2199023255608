import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import {connectRouter} from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import application from './application';
import auth from './auth';
import currentJurisdiction from './currentJurisdiction';
import dashboard from './dashboard';
import profile from './profile';
import content from './content';
import report from './report';
import settings from './settings';
import actions from './actions';
import search from './search';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'profile']
};

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  actions,
  application,
  auth,
  profile,
  currentJurisdiction,
  dashboard,
  content,
  report,
  settings,
  search,
});

export default history => persistReducer(persistConfig, rootReducer(history));
