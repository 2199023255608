import React, {Component, useState} from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  DatePicker,
  Typography
} from 'antd';
import PropTypes from 'prop-types';
import {
  REPORT_FORM_LAYOUT,
  REPORT_TABLE_PER_PAGE,
  DATE_FORMAT
} from '../../config/constants';
import {HTMLContent} from '../common';
import './index.css';

const {Option} = Select;
const {Paragraph} = Typography;

export default function ReportForm(props) {

  // const [searchPerformed, setSearchPerformed] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      getReportItems,
      form: {validateFields},
    } = props;

    validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }

      fieldsValue.size = REPORT_TABLE_PER_PAGE;
      fieldsValue.page = 1;
      fieldsValue.plan_ids = Array.isArray(fieldsValue.plan_ids) || !fieldsValue.plan_ids
        ? fieldsValue.plan_ids
        : [fieldsValue.plan_ids];
      fieldsValue.include_history = false;
      fieldsValue.condition = 'and';

      // setSearchPerformed(true)
      getReportItems(fieldsValue);
    });
  }

  const handlePlanChange = (selectedItems) => {
    const {onGetReportJurisdictions, form: {getFieldsValue}} = props;
    const params = getFieldsValue();
    params.jurisdiction_id = [];
    params.plan_ids = Array.isArray(selectedItems) ? selectedItems : [selectedItems];
    // setSearchPerformed(false)
    onGetReportJurisdictions({ids: selectedItems, params});
  }

  const renderPlansSelect = () => {
    const { options: { plans }, form: { getFieldDecorator }, params } = props;
    // `options.plans` are records from "jurisdictions" table where "jurisdiction_type" = 'plan'

    const filterOption = (input, option) => (option.props.children || '').toLowerCase().includes(input.toLowerCase())

    return (
      <Form.Item label="Plan">
        {
          getFieldDecorator('plan_ids', {
            initialValue: params.plan_ids || plans[0].id
          })(
            <Select showSearch filterOption={filterOption} id="plan_ids" onSelect={handlePlanChange} optionFilterProp="label" showArrow>
              { plans.map(item => (<Option value={item.id} label={item.label}>{item.name}</Option>)) }
            </Select>
          )
        }
      </Form.Item>
    );
  }

  // const handleJurisdictionChange = (selectedItems) => {
  //   setSearchPerformed(false)
  // }

  const renderJurisdictionsSelect = (label, field, optionName) => {
    const {options, form: {getFieldDecorator}, params} = props;

    const filterOption = (input, option) => (option.props.children || '').toLowerCase().includes(input.toLowerCase())

    return (
      <Form.Item label={label} extra="Defaults to all jurisdictions in the plan">
        {
          getFieldDecorator(field, {
            initialValue: params[field]
          })(
            <Select showSearch filterOption={filterOption} id={field} mode="multiple" optionFilterProp="label" showArrow>
              { options[optionName].map(item => (<Option value={item.id} label={item.label || item.name}>{item.label || item.name}</Option>)) }
            </Select>
          )
        }
      </Form.Item>
    );
  }

  const renderDate = (label, field) => {
    const {form: {getFieldDecorator}, params} = props;
    const hint = `Defaults to the the plan review cycle ${label.toLowerCase()}`

    return (
      <Form.Item label={label + ':'} className="form__date_full" extra={hint}>
        {
          getFieldDecorator(field, { initialValue: params[field] })(<DatePicker name={field} format={DATE_FORMAT}/>)
        }
      </Form.Item>
    );
  }

  const handleReportClick = () => {
    const { onGenerateReport, form: {validateFields} } = props;

    validateFields(async (err, fieldsValue) => {
      if (err) { return }

      fieldsValue.plan_ids = Array.isArray(fieldsValue.plan_ids) || !fieldsValue.plan_ids
        ? fieldsValue.plan_ids
        : [fieldsValue.plan_ids];
      fieldsValue.condition = 'and';

      onGenerateReport(fieldsValue);
    });
  }

  const renderForm = () => {
    const {isFetching, hasActions, isReportRuns} = props;

    const show_report_button = hasActions // && searchPerformed

    return (
      <>
        <p style={{ marginBottom: '2rem' }}>To generate a report, first select the plan, then select the jurisdiction(s) and click “Search”.<br />To generate a historic report, select start / end dates.</p>

        <div className="report-form-fields">
          {renderPlansSelect('Plan', 'plan_ids', 'plans')}
          {renderJurisdictionsSelect('Jurisdictions', 'jurisdiction_id', 'jurisdictions')}
          {renderDate('Start date', 'start_date')}
          {renderDate('End date', 'end_date')}

          <div className="ant-row">
            <div className="ant-col ant-col-xs-24 ant-col-sm-8  ant-col-md-12 ant-form-item-label"></div>
            <div className="ant-col ant-col-xs-24 ant-col-sm-16 ant-col-md-12 ant-form-item-control-wrapper btn-row">
              <Button type={show_report_button ? 'secondary' : 'primary'} htmlType="button" onClick={handleSubmit} loading={isFetching}>Search</Button>
              {
                show_report_button
                  ? <Button type="primary" htmlType="button" onClick={handleReportClick} loading={isReportRuns}>Generate Report</Button>
                  : null
              }
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Form {...REPORT_FORM_LAYOUT} className="search__form">
        <div className="form__section">
          {renderForm()}
        </div>
      </Form>
    </>
  )
}

ReportForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}).isRequired,
  onGenerateReport: PropTypes.func.isRequired,
  onGetReportJurisdictions: PropTypes.func.isRequired,
  getReportItems: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasActions: PropTypes.bool.isRequired,
  isReportRuns: PropTypes.bool.isRequired,
}
