import axios from 'axios';
import isString from 'lodash/isString';
import {store} from '../store';

const {REACT_APP_API_URL, NODE_ENV} = process.env;

const API_URL = NODE_ENV === 'development' ? REACT_APP_API_URL : '';

class Api {
  static get(route, data, params) {
    return this.xhr(route, data, params, 'get');
  }

  static put(route, data, params) {
    return this.xhr(route, data, params, 'put');
  }

  static post(route, data, params) {
    return this.xhr(route, data, params, 'post');
  }

  static delete(route, data, params) {
    return this.xhr(route, data, params, 'delete');
  }

  static replaceVariables(route, params) {
    Object.keys(params).forEach((key) => {
      route = route.replace(`:${key}`, params[key]);
    });
    return route;
  }

  static wrapApiErrors(error = {}) {
    try {
      const {status, data} = error.response || {};

      if (status === 401) {
        const {auth: {token}} = store.getState();
        if (token) {
          store.dispatch({type: 'AUTH_LOGOUT'});
          throw new Error();
        }
      }

      const {error: errorMessage} = data;

      if (!errorMessage) {
        throw new Error(data);
      }

      if (isString(errorMessage)) {
        throw new Error(errorMessage);
      }

      throw new Error('Unknown error');
    } catch (e) {
      throw e;
    }
  }

  static xhr(route, data = {}, params = {}, method) {
    const state = store.getState();

    const sendRequest = (axiosInstance) => {
      const url = Api.replaceVariables(route, params);
      const headers = {
        'Content-Type': 'application/json',
      };

      if (state.auth.token) {
        headers.Authorization = `Bearer ${state.auth.token}`;
      }

      const options = {
        baseURL: API_URL,
        url,
        method,
        headers,
        timeout: 15000,
      };

      if (method === 'get') {
        options.params = data;
      } else {
        options.data = data;
      }

      return axiosInstance(options)
        .then(res => res.data)
        .catch(err => Api.wrapApiErrors(err));
    };

    return sendRequest(axios.create());
  }
}

export default Api;
