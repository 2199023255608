import React, {Component} from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  Select,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import {DATE_ALTERNATIVE_FORMAT, MODAL_FORM_LAYOUT} from '../../../config/constants';

const {Option} = Select;

export default class PlanForm extends Component {
  static propTypes = {
    form: PropTypes.shape({}).isRequired,
    plan: PropTypes.shape({}).isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSavePlan: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired
  };

  state = {
    loading: false
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {toggleModal, onSavePlan, form: {validateFields}} = this.props;

    validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({loading: true});

      await onSavePlan(fieldsValue);
      this.setState({loading: false});
      toggleModal();
    });
  };

  render() {
    const {
      form: {getFieldDecorator},
      toggleModal,
      plan,
      // role
    } = this.props;

    function shouldDisableField() {
      // if (isEnabledForClient && role === 'client_admin') {
      //   return false;
      // }
      // if (role === 'tt_admin') {
      //   return false;
      // }
      return true;
    }

    const {loading} = this.state;
    const config = {
      rules: [{type: 'object', required: true, message: 'Please select date!'}],
    };
    const options = [{
      value: 'weekly',
      label: 'Weekly'
    }, {
      value: 'monthly',
      label: 'Monthly'
    }, {
      value: 'qarterly',
      label: 'Qarterly'
    }, {
      value: 'semi_annual',
      label: 'Semi Annual'
    }, {
      value: 'annual',
      label: 'Annual'
    }, {
      value: 'bi_annual',
      label: 'Bi Annual'
    }, {
      value: 'five_year',
      label: 'Five a Year'
    }, {
      value: 'other',
      label: 'Other'
    }];

    return (
      <Form {...MODAL_FORM_LAYOUT} onSubmit={this.handleSubmit} className="poc-form">
        <Row>
          <Col span={12}>
            <Form.Item label="Plan Name">
              {getFieldDecorator('name', {
                initialValue: plan.name,
              })(<Input placeholder="Please enter Plan Name" disabled={shouldDisableField()}/>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Review Cycle Frequency">
              {getFieldDecorator('review_cycle_frequency', {
                initialValue: plan.review_cycle_frequency,
                rules: [{required: true, message: 'Please select your gender!'}],
              })(
                <Select disabled={shouldDisableField(true)}>
                  {options.map(
                    option => (<Option key={`k_${option.value}`} value={option.value}>{option.label}</Option>)
                  )}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Plan Approval Date">
              {getFieldDecorator('approval_date', {...config, initialValue: moment.utc(plan.approval_date, DATE_ALTERNATIVE_FORMAT)})(<DatePicker format={DATE_ALTERNATIVE_FORMAT} disabled={shouldDisableField()}/>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Review Cycle Open Date">
              {getFieldDecorator('review_start_date', {...config, initialValue: moment.utc(plan.review_start_date, DATE_ALTERNATIVE_FORMAT)})(<DatePicker format={DATE_ALTERNATIVE_FORMAT} disabled={shouldDisableField()}/>)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Expiration Date">
              {getFieldDecorator('expiration_date', {...config, initialValue: moment.utc(plan.expiration_date, DATE_ALTERNATIVE_FORMAT)})(<DatePicker format={DATE_ALTERNATIVE_FORMAT} disabled={shouldDisableField()}/>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Review Cycle Close Date">
              {getFieldDecorator('review_end_date', {...config, initialValue: moment.utc(plan.review_end_date, DATE_ALTERNATIVE_FORMAT)})(<DatePicker format={DATE_ALTERNATIVE_FORMAT} disabled={shouldDisableField()}/>)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Scheduled Update Start Date">
              {getFieldDecorator('start__date', {...config, initialValue: moment.utc(plan.start_date, DATE_ALTERNATIVE_FORMAT)})(<DatePicker format={DATE_ALTERNATIVE_FORMAT} disabled={shouldDisableField()}/>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Scheduled Update Close Date">
              {getFieldDecorator('end_date', {...config, initialValue: moment.utc(plan.end_date, DATE_ALTERNATIVE_FORMAT)})(<DatePicker format={DATE_ALTERNATIVE_FORMAT} disabled={shouldDisableField()}/>)}
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer">
          <Button key="close" onClick={toggleModal}>
            Close
          </Button>
          { !shouldDisableField(true)
            ? (
              <Button
                key="save"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Save
              </Button>
            )
            : null
          }
        </div>
      </Form>
    );
  }
}
