import omit from 'lodash/omit';
import {
  CURRENT_JURISDICTION_GET_REQUEST,
  CURRENT_JURISDICTION_GET_SUCCESS,
  JURISCDICTION_CONTACT_UPDATE_SUCCESS,
  API_ERROR
} from '../actions/types';

import {createReducer} from '../utils';

const initialState = {
  plan: {},
  contact: {},
  descendants: [],
  actions: [],
  isFetching: false,
};
const currentJurisdictionReducer = createReducer(initialState, {
  [CURRENT_JURISDICTION_GET_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [JURISCDICTION_CONTACT_UPDATE_SUCCESS]: (state, {contact}) => ({
    ...state,
    contact
  }),
  [CURRENT_JURISDICTION_GET_SUCCESS]: (state, {currentJurisdiction}) => ({
    ...currentJurisdiction.jurisdiction,
    ...omit(currentJurisdiction, 'jurisdiction'),
    isFetching: false
  }),
  [API_ERROR]: state => ({...state, isFetching: false}),
});

export default currentJurisdictionReducer;
