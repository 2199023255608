import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Wrapper} from '../../components';
import {
  DashboardPage,
  AboutPage,
  PlanActionsPage,
  ReportsPage,
  ResourcesPage,
  SearchPage,
  JurisdictionPage,
  ActionCreatePage,
  ActionPage,
} from '../pages';
import './index.css';

function RootContainer({isAuthenticated}) {
  if (!isAuthenticated) {
    return <Redirect to="/login"/>;
  }

  return (
    <Wrapper>
      <Route exact path="/" component={DashboardPage}/>
      <Route exact path="/dashboard" component={DashboardPage}/>
      <Route path="/dashboard/jurisdiction/:id" component={JurisdictionPage}/>
      <Route path="/plan-actions" component={PlanActionsPage}/>
      <Route path="/search" component={SearchPage}/>
      <Route path="/reports" component={ReportsPage}/>
      <Route path="/resources" component={ResourcesPage}/>
      <Route path="/about" component={AboutPage}/>
      <Route exact path="/action/:id" component={ActionPage}/>
      <Route exact path="/create/action/:id" component={ActionCreatePage}/>
    </Wrapper>
  );
}

RootContainer.propTypes = {
  isAuthenticated: PropTypes.bool,
};

RootContainer.defaultProps = {
  isAuthenticated: false
};

const mapStateToProps = ({auth}) => ({
  isAuthenticated: auth.isAuthenticated
});

const bindActions = dispatch => ({});

export default connect(mapStateToProps, bindActions)(RootContainer);
