import ApiClient from '../../utils/api';
import {
  REPORT_GET_OPTIONS_REQUEST,
  REPORT_GET_OPTIONS_SUCCESS,
  REPORT_GET_ACTIONS_REQUEST,
  REPORT_GET_ACTIONS_SUCCESS,
  REPORT_GET_JURISDICTIONS_REQUEST,
  REPORT_GET_JURISDICTIONS_SUCCESS,
  REPORT_GENERATE_REQUEST,
  REPORT_GENERATE_SUCCESS,
  API_ERROR,
} from '../types';
import {
  API_REPORT_DROPDOWNS,
  API_REPORT,
  API_REPORT_JURISDICTIONS,
  API_GENERATE_REPORT,
} from '../../config/constants';
import {store} from '../../store';

export function getReportJurisdictions({ids, params}) {
  return async (dispatch) => {
    try {
      dispatch({type: REPORT_GET_JURISDICTIONS_REQUEST});

      const jurisdictions = await ApiClient.get(API_REPORT_JURISDICTIONS, {ids: [ids]});

      dispatch({
        type: REPORT_GET_JURISDICTIONS_SUCCESS,
        jurisdictions,
        params
      });
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function getReportOptions() {
  return async (dispatch) => {
    try {
      dispatch({type: REPORT_GET_OPTIONS_REQUEST});

      const options = await ApiClient.get(API_REPORT_DROPDOWNS);
      const jurisdictions = await ApiClient.get(
        API_REPORT_JURISDICTIONS,
        {ids: [options.plans[0].id]}
      );

      dispatch({
        type: REPORT_GET_OPTIONS_SUCCESS,
        availableJurisdictions: options.jurisdictions,
        jurisdictions,
        options
      });
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function getReportItems(params) {
  return async (dispatch) => {
    try {
      dispatch({type: REPORT_GET_ACTIONS_REQUEST});

      const {items, count} = await ApiClient.post(API_REPORT, {...params});

      dispatch({
        type: REPORT_GET_ACTIONS_SUCCESS,
        actions: items,
        total: count,
        params
      });
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function generateReport(params) {
  return async (dispatch) => {
    try {
      dispatch({type: REPORT_GENERATE_REQUEST});

      const link = await ApiClient.get(API_GENERATE_REPORT, {
        ...params,
        jurisdiction_ids: params.jurisdiction_id,
        plan_jurisdiction_id: params.plan_ids[0],
      });
      console.log('Link:', link)

      const uri = await new URL(link)
      uri.searchParams.set('token', store.getState().auth.token)
      console.log('URI with token', uri.toString())

      window.open(uri, '_blank')
      dispatch({type: REPORT_GENERATE_SUCCESS});
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}
