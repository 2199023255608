import React, {PureComponent} from 'react';
import {
  Form, Icon, Input, Button
} from 'antd';
import PropTypes from 'prop-types';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class LoginForm extends PureComponent {
  static propTypes = {
    form: PropTypes.shape({
      validateFields: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsError: PropTypes.func.isRequired,
      getFieldError: PropTypes.func.isRequired,
      isFieldTouched: PropTypes.func.isRequired,
    }).isRequired,
    onUserLogin: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {form: {validateFields}} = this.props;
    validateFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {form: {validateFields}, onUserLogin} = this.props;
    validateFields(async (err, values) => {
      if (!err) {
        onUserLogin(values);
      }
    });
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        getFieldsError,
        getFieldError,
        isFieldTouched,
      }
    } = this.props;

    const userNameError = isFieldTouched('userName') && getFieldError('userName');
    const passwordError = isFieldTouched('password') && getFieldError('password');

    return (
      <Form layout="vertical" onSubmit={this.handleSubmit} autoComplete="off">
        <Form.Item
          validateStatus={userNameError ? 'error' : ''}
          help={userNameError || ''}
        >
          {getFieldDecorator('userName', {
            rules: [{required: true, message: 'Please input your username!'}],
          })(
            <Input
              autocomplete="username"
              placeholder="Username"
              prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
              required=""
            />
          )}
        </Form.Item>
        <Form.Item
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError || ''}
        >
          {getFieldDecorator('password', {
            rules: [{required: true, message: 'Please input your Password!'}],
          })(
            <Input.Password
              autocomplete="current-password"
              placeholder="Password"
              prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
              required=""
              type="password"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>

    );
  }
}

export default Form.create({name: 'horizontal_login'})(LoginForm);
