import ApiClient from '../../utils/api';
import {
  CONTENT_REQUEST,
  ABOUT_SUCCESS,
  RESOURCES_SUCCESS,
  API_ERROR
} from '../types';
import {API_ABOUT, API_RESOURCES} from '../../config/constants';

export function getAbout() {
  return async (dispatch) => {
    try {
      dispatch({type: API_ERROR, error: null});
      dispatch({type: CONTENT_REQUEST});
      const about = await ApiClient.get(API_ABOUT);
      dispatch({type: ABOUT_SUCCESS, about});
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function getResources() {
  return async (dispatch) => {
    try {
      dispatch({type: API_ERROR, error: null});
      dispatch({type: CONTENT_REQUEST});
      const resources = await ApiClient.get(API_RESOURCES);
      dispatch({type: RESOURCES_SUCCESS, resources});
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}
