import {forwardTo} from '../../utils';
import ApiClient from '../../utils/api';
import {
  API_ERROR,
  ACTION_GET_OPTIONS_SUCCESS,
  ACTIONS_GET_REQUEST,
  ACTIONS_GET_SUCCESS,
  ACTION_GET_REQUEST,
  ACTION_GET_SUCCESS,
  ACTION_UPDATE_SUCCESS,
  ACTIONS_UPDATE_REQUEST,
  ACTION_CREATE_REQUEST,
  ACTION_CREATE_SUCCESS,
  RESET_CURRENT_ACTION,
  ACTION_SAVE_FORM,
} from '../types';
import {
  API_PLAN_ACTIONS,
  API_ACTIONS,
  API_ACTION,
  API_ACTION_DROPDOWNS,
} from '../../config/constants';
import {showMessage} from '../application';

export function getActions(params) {
  return async (dispatch) => {
    try {
      dispatch({type: ACTIONS_GET_REQUEST});

      const {sorterParams} = params;
      const {items, total} = await ApiClient.get(API_PLAN_ACTIONS, params);

      dispatch({
        type: ACTIONS_GET_SUCCESS,
        items,
        total,
        currentPage: params.currentPage,
        sorterParams
      });
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function getAction({id}) {
  return async (dispatch) => {
    try {
      dispatch({type: ACTION_GET_REQUEST});

      const action = await ApiClient.get(API_ACTION, {}, {id});

      dispatch({
        type: ACTION_GET_SUCCESS,
        action
      });
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function updateAction({id, fieldsValue}) {
  return async (dispatch) => {
    try {
      dispatch({type: ACTIONS_UPDATE_REQUEST});

      const action = await ApiClient.put(API_ACTION, {...fieldsValue}, {id});

      dispatch({
        type: ACTION_UPDATE_SUCCESS,
        currentAction: action
      });
      dispatch(showMessage('Action was updated successfully'));
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function createAction({jurisdictionId, fieldsValue}) {
  return async (dispatch) => {
    try {
      dispatch({type: ACTION_CREATE_REQUEST});

      const currentAction = await ApiClient.post(API_ACTIONS, {
        ...fieldsValue,
        jurisdiction_id: jurisdictionId
      });

      await dispatch({
        type: ACTION_CREATE_SUCCESS,
        currentAction
      });

      forwardTo(`/action/${currentAction.id}`);
      dispatch(showMessage('Action was added successfully'));
    } catch (e) {
      dispatch({type: ACTION_SAVE_FORM, currentAction: fieldsValue});
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function getActionOptions() {
  return async (dispatch) => {
    try {
      const options = await ApiClient.get(API_ACTION_DROPDOWNS);

      dispatch({
        type: ACTION_GET_OPTIONS_SUCCESS,
        options
      });
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function currentActionReset(jurisdiction_name) {
  return {type: RESET_CURRENT_ACTION, jurisdiction_name};
}
