import {Modal, Button, Icon} from 'antd';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {HINTS} from '../../../config/constants';
import HTMLContent from '../HTMLContent';

const ModalHint = (props) => {
  const {field} = props;
  const [isVisible, toggleVisibility] = useState(false);

  const handleHintClose = () => {
    toggleVisibility(false);
  };

  const showModalHint = () => {
    toggleVisibility(true);
  };

  return (
    <>
      <Icon type="question-circle-o" theme="twoTone" twoToneColor="#52c41a" onClick={() => showModalHint(field)}/>
      <Modal
        width={700}
        title={HINTS[field].name}
        visible={isVisible}
        onCancel={handleHintClose}
        footer={[
          <Button key="back" onClick={handleHintClose}>
            Return
          </Button>
        ]}
      >
        <HTMLContent content={HINTS[field].hint}/>
      </Modal>
    </>
  );
};

ModalHint.propTypes = {
  field: PropTypes.string.isRequired,
};

export default ModalHint;
