import React, {PureComponent} from 'react';
import {
  Drawer, Form, Button, Col, Row, Input
} from 'antd';
import PropTypes from 'prop-types';
import {InputMask} from '../common';
import './index.css';
import {PHONE_PATTERN} from '../../config/constants';

class ProfileForm extends PureComponent {
  static propTypes = {
    getProfile: PropTypes.func.isRequired,
    saveProfile: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    form: PropTypes.shape({}).isRequired,
    visible: PropTypes.bool.isRequired,
    profile: PropTypes.shape({
      user: PropTypes.shape({
        username: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }

  componentDidMount() {
    const {getProfile} = this.props;
    getProfile();
  }

  onClose = () => {
    const {onClose} = this.props;
    onClose();
  };

  onSave = () => {
    const {form, saveProfile} = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        saveProfile(this.serialize(values));
      }
    });
  }

  compareToPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Confirmation doesn\'t match your password!');
    } else {
      callback();
    }
  }

  serialize = values => Object.keys(values).reduce((acc, key) => {
    if (values[key]) {
      acc[key] = values[key];
    }
    return acc;
  }, {});

  render() {
    const {visible, form, profile: {user, isFetching}} = this.props;
    const {
      username,
      email,
      first_name,
      last_name,
      phone_number,
    } = user;

    const {getFieldDecorator} = form;

    return (
      <Drawer
        className="profile-form"
        title="Edit your Profile"
        width={720}
        onClose={this.onClose}
        visible={visible}
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Username">
                {getFieldDecorator('username', {
                  rules: [{required: true, message: 'Please enter user name'}],
                  initialValue: username
                })(
                  <Input
                    className="profile-form__item_field"
                    placeholder="Please enter user name"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Email">
                <Input
                  className="profile-form__item_field"
                  disabled
                  value={email}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="First Name">
                {getFieldDecorator('first_name', {
                  initialValue: first_name
                })(
                  <Input
                    className="profile-form__item_field"
                    placeholder="Please enter your First Name"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name">
                {getFieldDecorator('last_name', {
                  initialValue: last_name
                })(
                  <Input
                    className="profile-form__item_field"
                    placeholder="Please enter your Last Name"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Phone Number">
                {getFieldDecorator('phone_number', {
                  initialValue: phone_number,
                  rules: [
                    {
                      pattern: PHONE_PATTERN,
                      message: 'Please enter a valid phone number'
                    }
                  ]
                })(
                  <InputMask
                    className="profile-form__item_field"
                    mask="(999) 999-9999"
                    placeholder="Please enter your Phone Number"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Password">
                {getFieldDecorator('password', {
                  rules: [{
                    min: 8,
                    message: 'Minimum 8 characters'
                  }]
                })(
                  <Input.Password placeholder="Enter new Password"/>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Re-enter Password">
                {getFieldDecorator('password_confirmation', {
                  rules: [
                    {
                      validator: this.compareToPassword,
                    }
                  ]
                })(
                  <Input.Password placeholder="Confirm new Password"/>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div
          className="profile-form__footer"
        >
          <Button onClick={this.onClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={this.onSave} type="primary" loading={isFetching}>
            Submit
          </Button>
        </div>
      </Drawer>
    );
  }
}

export default Form.create()(ProfileForm);
