import ApiClient from '../../utils/api';
import {
  API_ERROR,
  DASHBOARD_GET_REQUEST,
  DASHBOARD_PLANS_GET_SUCCESS,
  JURISDICTION_GET_REQUEST,
  JURISDICTION_GET_SUCCESS,
  CURRENT_JURISDICTION_GET_REQUEST,
  CURRENT_JURISDICTION_GET_SUCCESS,
  JURISCDICTION_CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_SUCCESS,
  PLAN_UPDATE_SUCCESS
} from '../types';
import {
  API_DASHBOARD_PLANS,
  API_JURISDICTION_WITH_RELATED_DETAILS,
  API_JURISDICTION,
  API_CONTACT,
  API_PLAN,
} from '../../config/constants';
import {showMessage} from '../application';

export function getJurisdictionWithDetails() {
  return async (dispatch) => {
    try {
      dispatch({type: JURISDICTION_GET_REQUEST});

      const jurisdiction = await ApiClient.get(API_JURISDICTION_WITH_RELATED_DETAILS);

      dispatch({type: JURISDICTION_GET_SUCCESS, jurisdiction});
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function getPlans() {
  return async (dispatch) => {
    try {
      dispatch({type: DASHBOARD_GET_REQUEST});

      const plans = await ApiClient.get(API_DASHBOARD_PLANS);

      dispatch({type: DASHBOARD_PLANS_GET_SUCCESS, plans});
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function getCurrentJurisdiction(params) {
  return async (dispatch) => {
    try {
      dispatch({type: CURRENT_JURISDICTION_GET_REQUEST});

      const currentJurisdiction = await ApiClient.get(API_JURISDICTION, {}, {id: params.id});

      dispatch({type: CURRENT_JURISDICTION_GET_SUCCESS, currentJurisdiction});
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function saveContact(params) {
  return async (dispatch, getState) => {
    const {contact} = getState();
    try {
      await ApiClient.put(API_CONTACT, params);
      dispatch({type: CONTACT_UPDATE_SUCCESS, contact: {...contact, ...params}});
      dispatch(showMessage('Contacts were successfully updated.'));
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function saveJurisdiictionContact(params) {
  return async (dispatch, getState) => {
    const {contact} = getState();
    try {
      await ApiClient.put(API_CONTACT, params);
      dispatch({type: JURISCDICTION_CONTACT_UPDATE_SUCCESS, contact: {...contact, ...params}});
      dispatch(showMessage('Contacts were successfully updated.'));
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function savePlan(params) {
  return async (dispatch, getState) => {
    const {jurisdiction: {plan}} = getState();
    try {
      await ApiClient.put(API_PLAN, params, {id: plan.id});
      dispatch({type: PLAN_UPDATE_SUCCESS, plan: {...plan, ...params}});
      dispatch(showMessage('Plans were successfully updated.'));
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}
