import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
} from 'antd';
import {Chart, Bars} from '../common';

function JurisdictionCharts(props) {
  const {title, data, hintField} = props;

  return (
    <Card className="card_height_full">
      <Row>
        <Col span={12} style={{height: '200px'}}>
          <Chart jurisdictionProgress={data}/>
        </Col>
        <Col span={12}>
          <Bars title={title} jurisdictionProgress={data} hintField={hintField}/>
        </Col>
      </Row>
    </Card>
  );
}

JurisdictionCharts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hintField: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default JurisdictionCharts;
