import ApiClient from '../../utils/api';
import {
  API_ERROR,
  SEARCH_GET_SUCCESS,
  SEARCH_GET_OPTIONS_SUCCESS,
  SEARCH_GET_JURISDICTIONS_SUCCESS,
  SEARCH_GET_ACTIONS_SUCCESS,
  SEARCH_GET_REQUEST,
  SEARCH_REQUEST,
  SEARCH_OPTIONS_REQUEST,
  SEARCH_EXCEL_REQUEST,
  SEARCH_EXCEL_SUCCESS,
} from '../types';
import {
  API_SEARCH_DROPDOWNS,
  API_SEARCH_JURISDICTIONS,
  API_SEARCH_ACTIONS,
  API_SEARCH_EXCEL,
  API_SEARCH,
} from '../../config/constants';
import {store} from '../../store';

export function getSearchOptions() {
  return async (dispatch) => {
    try {
      dispatch({type: SEARCH_OPTIONS_REQUEST});

      const options = await ApiClient.get(API_SEARCH_DROPDOWNS);

      dispatch({
        type: SEARCH_GET_OPTIONS_SUCCESS,
        availableJurisdictions: options.jurisdictions,
        options
      });
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function getSearchItems(params) {
  return async (dispatch) => {
    try {
      dispatch({type: SEARCH_GET_REQUEST});
      const {sorterParams} = params;
      const {items, count} = await ApiClient.post(API_SEARCH, {...params});

      dispatch({
        type: SEARCH_GET_SUCCESS,
        actions: items,
        total: count,
        sorterParams,
        params
      });
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function getSearchJurisdictions({ids, params}) {
  return async (dispatch) => {
    try {
      dispatch({type: SEARCH_REQUEST});

      const jurisdictions = await ApiClient.get(API_SEARCH_JURISDICTIONS, {ids});

      dispatch({
        type: SEARCH_GET_JURISDICTIONS_SUCCESS,
        jurisdictions,
        params
      });
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function getSearchActions({ids, params}) {
  return async (dispatch) => {
    try {
      dispatch({type: SEARCH_REQUEST});

      const actions = await ApiClient.get(API_SEARCH_ACTIONS, {ids});

      dispatch({
        type: SEARCH_GET_ACTIONS_SUCCESS,
        actions,
        params
      });
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function generateExcel(params) {
  return async (dispatch) => {
    try {
      dispatch({type: SEARCH_EXCEL_REQUEST});

      const link = await ApiClient.post(API_SEARCH_EXCEL, params)
      console.log('Link:', link)

      const uri = await new URL(link)
      uri.searchParams.set('token', store.getState().auth.token)
      console.log('URI with token', uri.toString())

      window.open(uri, '_blank')
      dispatch({type: SEARCH_EXCEL_SUCCESS});
    } catch (e) {
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}
