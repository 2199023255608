import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Spin, Typography} from 'antd';
import './index.css';
import {getAbout} from '../../../actions/content';
import {HTMLContent} from '../../../components/common';

const {Title} = Typography;

function AboutPage(props) {
  const {
    onGetAbout, content, title, isFetching
  } = props;

  useEffect(() => {
    onGetAbout();
  }, []);

  return (
    isFetching
      ? (<div className="spin__wrapper"><Spin size="large"/></div>)
      : (
        <div className="content__wrapper">
          <Title level={3}>{title}</Title>
          <HTMLContent content={content}/>
        </div>
      )
  );
}

AboutPage.propTypes = {
  onGetAbout: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired
};

const mapStateToProps = ({content: {about: {title, content}, isFetching}}) => ({
  content, title, isFetching
});

const bindActions = dispatch => ({
  onGetAbout: () => dispatch(getAbout())
});

export default connect(mapStateToProps, bindActions)(AboutPage);
