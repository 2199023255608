import React from 'react';
import {Form, Radio} from 'antd';
import {ACTION_FORM_FIELDS} from '../../../config/constants';
import {shouldDisableField} from '../../../utils/shouldDisableField';

export function RadioInput(props) {
  const {
    values, items, field, isRequired, currentAction, form: {getFieldDecorator}, initialValue
  } = props;

  const defaultValues = [
    {value: true, name: 'Yes'},
    {value: false, name: 'No'}
  ];

  const radioValues = values || defaultValues;

  const handleChange = (e) => {
    const {value} = e.target;
    const values = {};

    values[field] = value;
    currentAction[field] = value;
  };

  // Boolean value in “initialValue” is resolved incorrectly: when the value in DB is `null`, here we receive `true`.
  // But first we need to discuss this with the client.
  const valueWithFallback = () => {
    const val = currentAction[field];
    return (typeof val === 'undefined') ? initialValue : val
  }

  return (
    <Form.Item>
      {getFieldDecorator(field, {
        initialValue: currentAction[field] || initialValue,
        rules: [
          {
            required: isRequired,
            message: `${ACTION_FORM_FIELDS[field]} is required`
          }
        ],
      })(
        <Radio.Group>
          {
            radioValues.map(item => (<Radio key={item.value} value={item.value} onChange={handleChange} disabled={shouldDisableField(props, field)}>{item.name}</Radio>))
          }
        </Radio.Group>
      )}
    </Form.Item>
  );
}
