import {
  SEARCH_GET_SUCCESS,
  SEARCH_GET_OPTIONS_SUCCESS,
  SEARCH_GET_OPTIONS_REQUEST,
  SEARCH_OPTIONS_REQUEST,
  SEARCH_REQUEST,
  SEARCH_GET_JURISDICTIONS_SUCCESS,
  SEARCH_GET_ACTIONS_SUCCESS,
  SEARCH_GET_REQUEST,
  SEARCH_EXCEL_REQUEST,
  SEARCH_EXCEL_SUCCESS,
  API_ERROR
} from '../actions/types';

import {createReducer} from '../utils';

const initialState = {
  availableJurisdictions: [],
  actions: [],
  currentPage: 1,
  options: null,
  areParamsFetching: false,
  isSearchFetching: false,
  isExcelFetching: false,
  sorterParams: {
    field: 'jurisdiction_id',
    order: 'asc',
  },
  params: {
    plans: [],
    jurisdiction_id: [],
    action_id: [],
    action_status_id: [],
    asset_type_id: [],
    crs_option_id: [],
    estimated_benfit_level_id: [],
    estimated_cost_level_id: [],
    hazard_option_id: [],
    mitigation_option_id: [],
    previous_action_status: [],
    priority_option_id: [],
    review_status_id: [],
    timeline_option_id: [],
    search_text: '',
    end_date: null,
    start_date: null,
    plan_approval_date: null,
    condition: 'or',
    include_history: false,
    searchSubmitted: false,
  }
};

const searchReducer = createReducer(initialState, {
  [SEARCH_GET_OPTIONS_REQUEST]: () => ({
    ...initialState,
    areParamsFetching: true,
  }),
  [SEARCH_GET_REQUEST]: state => ({
    ...state,
    isSearchFetching: true,
  }),
  [SEARCH_EXCEL_REQUEST]: state => ({
    ...state,
    isExcelFetching: true,
  }),
  [SEARCH_EXCEL_SUCCESS]: state => ({
    ...state,
    isExcelFetching: false,
  }),
  [SEARCH_OPTIONS_REQUEST]: state => ({
    ...state,
    areParamsFetching: true,
  }),
  [SEARCH_REQUEST]: state => ({
    ...state,
    areParamsFetching: true,
  }),
  [SEARCH_GET_OPTIONS_SUCCESS]: (state, {options, availableJurisdictions}) => ({
    ...state,
    areParamsFetching: false,
    availableJurisdictions,
    options,
  }),
  [SEARCH_GET_SUCCESS]: (state, {
    actions,
    total,
    params,
    sorterParams
  }) => ({
    ...state,
    isSearchFetching: false,
    actions,
    params,
    currentPage: params.page,
    searchSubmitted: true,
    sorterParams,
    total,
  }),
  [SEARCH_GET_JURISDICTIONS_SUCCESS]: (state, {jurisdictions, params}) => ({
    ...state,
    areParamsFetching: false,
    options: {
      ...state.options,
      jurisdictions,
    },
    params,
  }),
  [SEARCH_GET_ACTIONS_SUCCESS]: (state, {actions, params}) => ({
    ...state,
    areParamsFetching: false,
    options: {
      ...state.options,
      actions,
    },
    params,
  }),
  [API_ERROR]: state => ({
    ...state,
    areParamsFetching: false,
    isSearchFetching: false,
    isExcelFetching: false,
  }),
});

export default searchReducer;
