import React, {useRef} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  Layout,
  Typography,
  Row,
  Col,
  notification
} from 'antd';
import './index.css';
import whiteBALogo from '../../assets/images/BATool-logo-1.svg';
import tetraLogo from '../../assets/images/TT_logowhite.png';
import {LoginForm, ToSModal} from '../../components';
import {loginUser, onToSAccept} from '../../actions/auth';

const {Title} = Typography;

const {
  Content,
} = Layout;

function LoginContainer(props) {
  const {
    logInUser,
    error,
    isAuthenticated,
    onToSAccepted,
    termsAccepted,
    hasToken
  } = props;

  const modal = useRef(null);

  if (isAuthenticated) {
    return <Redirect to="/"/>;
  }

  if (modal.current && hasToken && !termsAccepted) {
    modal.current.showModal();
  }

  if (termsAccepted && hasToken) {
    onToSAccepted();
  }

  if (error) {
    notification.error({message: error});
  }

  return (
    <Content className="login-container">
      <Row type="flex" justify="end" gutter={16}>
        <Col className="login-container__heading">
          <img src={whiteBALogo} alt=""/>
          <Title>Hazard Mitigation Plan Review Tool</Title>
          <Title level={3}>Progress Reporting</Title>
        </Col>
      </Row>
      <Row>
        <Col span={4} offset={4} className="login-container__form">
          <LoginForm onUserLogin={logInUser}/>
        </Col>
      </Row>
      <Row>
        <Col span={4} offset={20} className="login-container__footer">
          <img src={tetraLogo} alt="TT"/>
        </Col>
      </Row>
      <ToSModal
        ref={modal}
        onClose={onToSAccepted}
      />
    </Content>
  );
}

LoginContainer.propTypes = {
  logInUser: PropTypes.func.isRequired,
  error: PropTypes.string,
  onToSAccepted: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  termsAccepted: PropTypes.bool,
  hasToken: PropTypes.bool,
};

LoginContainer.defaultProps = {
  error: '',
  isAuthenticated: false,
  hasToken: false,
  termsAccepted: false
};

const mapStateToProps = ({application, auth}) => ({
  error: application.error,
  isAuthenticated: auth.isAuthenticated,
  hasToken: Boolean(auth.token),
  termsAccepted: auth.terms
});

const bindActions = dispatch => ({
  logInUser: params => dispatch(loginUser(params)),
  onToSAccepted: () => dispatch(onToSAccept()),
});

export default connect(mapStateToProps, bindActions)(LoginContainer);
