export function shouldDisableField(props, fieldName) {
  const {options, form: {getFieldsValue, getFieldValue}, create} = props;

  switch (fieldName) {
    case 'reviewer':
      return false;
    case 'action_status_id':
      return !getFieldValue('reviewer');
    case 'name':
      return !create;
    case 'previous_action_status_id':
      return true;
    case 'bca_ratio':
      return disable(getFieldValue, lock(getFieldsValue), status(options, getFieldValue), 'benefit_cost_analysis');
    case 'bca_total_benefits':
      return disable(getFieldValue, lock(getFieldsValue), status(options, getFieldValue), 'benefit_cost_analysis');
    case 'bca_final_cost':
      return disable(getFieldValue, lock(getFieldsValue), status(options, getFieldValue), 'benefit_cost_analysis');
    default:
      return fieldCompleteStatusRelated(fieldName)
        ? !(!lock(getFieldsValue) && completed(status(options, getFieldValue))) : lock(getFieldsValue);
  }
}

function status(options, getFieldValue) {
  return options.action_status.find(option => (option.id === getFieldValue('action_status_id')))
}

function disable(getFieldValue, formLocked, status, relatedField) {
  return !(getFieldValue(relatedField) && !formLocked && completed(status))
}

function fieldCompleteStatusRelated(fieldName) {
  const fieldsAfterActionComplete = ['benefit_cost_analysis', 'level_protection',
    'losses_avoided', 'grant_funded', 'grant_funding_source_id'];

  return fieldsAfterActionComplete.includes(fieldName) === true;
}

function completed(status) {
  return status && status.label === 'Completed';
}

function lock(getFieldsValue) {
  return Object.values(getFieldsValue(['reviewer', 'action_status_id'])).some(item => Boolean(item) === false)
}
