import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  PieChart,
  Pie,
  // Legend,
  Cell,
  // Tooltip,
  ResponsiveContainer,
  Sector,
  // Label,
  // LabelList,
  // BarChart,
  // Bar,
  // XAxis,
  // YAxis
} from 'recharts';

const Chart = (props) => {
  const {jurisdictionProgress, isJurisdiction} = props;
  const totalProgress = jurisdictionProgress.reduce((acc, item) => acc + item.value, 0);
  const completeLabels = ['Review Complete', 'Complete', 'Completed'];
  const complete = jurisdictionProgress.find(item => completeLabels.includes(item.name));
  let firstFocus = 0;
  jurisdictionProgress.forEach((item, index) => {
    if (item.value) {
      firstFocus = index;
    }
  });
  const [activeIndex, setActiveIndex] = useState(firstFocus);
  // console.log(jurisdictionProgress, firstFocus);
  // setActiveIndex(firstFocus);

  function onPieEnter(data, index) {
    setActiveIndex(index);
  }

  function renderActiveShape(chartProps) {
    // const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      // midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      // data,
      fill,
      payload,
      // percent
    } = chartProps;
    // const sin = Math.sin(-RADIAN * midAngle);
    // const cos = Math.cos(-RADIAN * midAngle);
    // const sx = cx + (outerRadius + 10) * cos;
    // const sy = cy + (outerRadius + 10) * sin;
    // const mx = cx + (outerRadius + 30) * cos;
    // const my = cy + (outerRadius + 30) * sin;
    // const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    // const ey = my;
    // const textAnchor = cos >= 0 ? 'start' : 'end';

    const statuses = {
      'Review Not Started': 'Not Started',
      'Review In Progress': 'In Progress',
      'Review Complete': 'Complete',
      'Ongoing Capability': 'Ongoing'
    };

    const name = payload.name.trim();

    return (
      <g>
        {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {`${(
            complete.value ? ((100 / totalProgress) * complete.value) : 0
          ).toFixed(0)}% Complete`}
        </text> */}
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000">
          {`${(
            (100 / totalProgress) * payload.value
          ).toFixed(0)}% ${statuses[name] ? statuses[name] : name}`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 3}
          outerRadius={outerRadius + 5}
          fill={fill}
        />
        {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
          {payload.name}
        </text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(percent: ${(percent * 100).toFixed(2)}%)`}
        </text> */}
      </g>
    );
  }

  return (
    <>
      <h4 className="ant-typography chart__total">
        {
          isJurisdiction
            ? `Total # Jurisdictions: ${totalProgress}`
            : `Total: ${totalProgress}`}
      </h4>
      <ResponsiveContainer height="90%">
        <PieChart width={400} height={450}>
          <Pie
            data={jurisdictionProgress}
            dataKey="value"
            innerRadius="75%"
            outerRadius="95%"
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={onPieEnter}
            isAnimationActive={false}
          >
            {
              jurisdictionProgress.map((entry, index) => (
                <Cell key={`slice-${index}`} fill={entry.color}/>
              ))
            }
            {/* <Label value="test"/> */}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

Chart.propTypes = {
  jurisdictionProgress: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isJurisdiction: PropTypes.bool
};

Chart.defaultProps = {
  isJurisdiction: false
};

export default Chart;
