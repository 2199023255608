export default `<h2>
    BATool<sup>SM</sup>&nbsp;
    Privacy Statement
  </h2>
  <p>Tetra Tech recognizes and respects the privacy of users of this web site.  When you choose to provide us with information about yourself, Tetra Tech will act in a responsible manner. Tetra Tech may store and disclose personal information as allowed or required by applicable law, including making disclosures that are necessary or advisable to (a) protect the rights, safety or property of Tetra Tech or others and (b) conform to legal or regulatory requirements. Without your express approval, however, Tetra Tech will not trade, sell or rent your personal information but may collect and/or provide aggregate statistics about its web site and users to other parties. Tetra Tech has no intention of collecting data from individuals under 13 years of age. When you view certain parts of the web site, information may be stored on your computer. This information will be in the form of a "Cookie" or similar file and will permit tailoring of the web site to better match your interests and/or preferences. This site, however, contains links to other web sites and Tetra Tech does not control the privacy policies of those sites. See Linking. This privacy statement only applies to information collected and maintained by Tetra Tech.</p>
  <h2>Terms of Use</h2>
  <p>The following Terms of Use govern your use of the Tetra Tech, Inc. web site and the materials accessible on or from this site. Please read these Terms of Use carefully before accessing any part of the site. By acknowledging your acceptance of these Terms of Use, you acknowledge that a contract exists between you and Tetra Tech and that you are agreeing to be bound by these Terms of Use. If you do not agree with these Terms of Use, please do not access the web site.</p>
  <h2>Intellectual Property</h2>
  <p>The information on this web site, including without limitation all design, text, images, white papers, press releases and other information, is protected under United States and other copyright laws and is owned by Tetra Tech or used under license from the copyright owner. The information may not, except under written license, be copied, reproduced, transmitted, displayed, performed, distributed, rented, sublicensed, altered, stored for subsequent use or otherwise used in whole or in part in any manner without Tetra Tech's prior written consent, except to the extent such use is authorized under the United States copyright laws. Tetra Tech's trademarks, logos, images and service marks used on this site are the property of Tetra Tech and may not be used without permission from Tetra Tech and then only with proper acknowledgment.</p>
  <h2>Confidential Information</h2>
  <p>Tetra Tech discourages you from sending or posting to the web site any information that you consider to be confidential or proprietary. Please note that if you do send or post any such information or material, Tetra Tech will assume that it is not confidential. By sending or posting any information or material, you grant Tetra Tech an unrestricted, irrevocable, worldwide, non-exclusive license to use, reproduce, display, perform, modify, transmit and distribute those materials or information, and you agree that Tetra Tech is free to use any ideas, concepts, know-how or techniques that you send or post for any purpose, including to create derivative works therefrom.</p>
  <h2>No Representations and Warranties</h2>
  <p>While Tetra Tech attempts to make sure that only accurate and up-to-date information is presented on this web site, Tetra Tech assumes no responsibility for, and makes no representations with respect to, the accuracy of the information presented here. Therefore, all materials and information are presented "AS IS," and TETRA TECH EXPRESSLY DISCLAIMS ANY IMPLIED OR EXPRESS WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RELATING TO SUCH MATERIAL. IN NO EVENT SHALL TETRA TECH BE LIABLE FOR ANY CLAIM, LOSS, INJURY, OR DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION SPECIAL, INDIRECT, CONSEQUENTIAL OR INCIDENTAL DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES, HOWEVER CAUSED AND ON ANY THEORY RESULTING FROM THE USE OF OR INABILITY TO USE, OR RELIANCE ON, THE WEB SITE OR THE INFORMATION PRESENTED THEREON, INCLUDING WITHOUT LIMITATION LOSS OF PROFITS OR REVENUES, EVEN IF TETRA TECH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
  <h2>Privacy</h2>
  <p>Tetra Tech recognizes and respects the privacy of users of this web site. When you choose to provide us with information about yourself, Tetra Tech will act in a responsible manner. Tetra Tech may store and disclose personal information as allowed or required by applicable law, including making disclosures that are necessary or advisable to (a) protect the rights, safety or property of Tetra Tech or others and (b) conform to legal or regulatory requirements. Without your express approval, however, Tetra Tech will not trade, sell or rent your personal information but may collect and/or provide aggregate statistics about its web site and users to other parties. Tetra Tech has no intention of collecting data from individuals under 13 years of age. When you view certain parts of the web site, information may be stored on your computer. This information will be in the form of a "Cookie" or similar file and will permit tailoring of the web site to better match your interests and/or preferences. This site, however, contains links to other web sites and Tetra Tech does not control the privacy policies of those sites. See Linking. This privacy statement only applies to information collected and maintained by Tetra Tech.</p>
  <h2>Linking By Tetra Tech</h2>
  <p>As a convenience, this web site contains links to other sites that are not controlled by, or affiliated or associated with, Tetra Tech. Accordingly, Tetra Tech does not make any representations concerning the privacy practices or terms of use of such sites, nor does Tetra Tech control or guarantee the accuracy, integrity or quality of the data, text, software, music, sound, photographs, graphics, videos, messages or materials available on such sites. The inclusion or exclusion of a site does not imply endorsement by Tetra Tech of the site, the site's provider or the information on the site.</p>
  <h2>Linking and Framing Prohibited</h2>
  <p>You agree not to establish a link between any other web site and this web site, or permit anyone else to establish such a link, either to the home page or to any other page or subsidiary page, without express written permission herein. You agree not to frame any page or portion of this web site. The creation and/or the facilitation of the creation of links and/or frames to this web site is expressly prohibited and will be grounds for termination of your access to this web site, unless express written permission is granted.</p>
  <h2>Governing Law</h2>
  <p>The internal laws of the State of California, without reference to any choice of law provisions, shall govern any claims relating to this web site or to the materials on it. Tetra Tech may revise these Terms of Use at any time. Revisions will be posted on this "Terms of Use" page and users are responsible for reviewing this page from time to time to ensure compliance. Use of this web site after revisions have been posted will signify your consent to and agreement to be bound by the revised Terms of Use. You may terminate this Agreement at any time by refraining from using the Tetra Tech web site.</p>
`;
