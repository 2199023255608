import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {
  Empty,
  Row,
  Col,
  Typography,
  Statistic,
  Spin,
} from 'antd';
import PropTypes from 'prop-types';
import './index.css';
import {
  ActionsTable,
  JurisdictionsTable,
  PointOfContact,
  Plan,
  JurisdictionsCharts,
  ChartCard,
} from '../../../components';
import {
  getCurrentJurisdiction,
  saveJurisdiictionContact,
  savePlan,
} from '../../../actions/dashboard';

const {Title} = Typography;

function JurisdictionPage(props) {
  const {
    onGetCurrentJurisdiction,
    currentJurisdiction,
    currentJurisdiction: {
      contact,
      plan,
      descendants,
      actions,
      id,
    },
    isFetching,
    descendants_chart_statuses,
    actions_chart_by_review,
    actions_chart_by_status,
    match: {params},
    onSaveContact,
    onSavePlan,
    userRole
  } = props;

  useEffect(() => {
    onGetCurrentJurisdiction(params);
  }, [params]);

  return (
    <>
      {isFetching
        ? <div className="spin__wrapper"><Spin size="large"/></div>
        : (
          <>
            <Row className="dashboard__header">
              <Col span={12}>
                <Title level={3} className="dashboard__heading">
                  {`${currentJurisdiction.name}`}
                </Title>
              </Col>
              {
                currentJurisdiction.type !== 'plan' && (
                  <>
                    <Col span={3} className="buttons-gutter">
                      <PointOfContact
                        contact={contact}
                        onSaveContact={onSaveContact}
                        jurisdictionId={id}
                      />
                    </Col>
                    <Col span={7} className="dasboard__contacts">
                      <Col span={12} className="dasboard__contacts_primary">
                        <Statistic
                          title="Primary POC:"
                          value={
                            contact
                              ? `${contact.primary_first_name} ${contact.primary_last_name}`
                              : 'Empty'
                          }
                        />
                      </Col>
                      <Col span={12} className="dasboard__contacts_alternate">
                        <Statistic
                          title="Alternate POC:"
                          value={
                            contact
                              ? `${contact.alternate_first_name || ''} ${contact.alternate_last_name || ''}`
                              : 'Empty'
                          }
                        />
                      </Col>
                    </Col>
                    <Col span={2} className="ta-r">
                      <Plan plan={plan} role={userRole} onSavePlan={onSavePlan}/>
                    </Col>
                  </>
                )
              }
            </Row>
            {
              descendants.length
                ? (
                  <>
                    <JurisdictionsCharts jurisdictions={descendants_chart_statuses} plan={plan} title="Reporting Progress"/>
                    <JurisdictionsTable
                      jurisdictions={descendants}
                      isScrollable={Boolean(actions.length)}
                      currentJurisdictionId={currentJurisdiction.id}
                      isPlan={currentJurisdiction.type === 'plan'}
                    />
                  </>
                )
                : null
            }
            {
              actions.length
                ? (
                  <>
                    <Row type="flex">
                      <Col span={12} className="status__wrapper">
                        <ChartCard data={actions_chart_by_review} title="Reporting Progress" hintField="reporting_progress"/>
                      </Col>
                      <Col span={12} className="status__wrapper">
                        <ChartCard data={actions_chart_by_status} title="Action Status" hintField="action_status"/>
                      </Col>
                    </Row>
                    <ActionsTable actions={actions} jurisdictionId={id}/>
                  </>
                )
                : null
            }
            {
              !actions.length && !descendants.length
                ? (
                  <Empty>
                    <Title level={3}>There are no actions</Title>
                  </Empty>
                )
                : null
            }
          </>
        )
      }
    </>
  );
}

JurisdictionPage.propTypes = {
  userRole: PropTypes.string.isRequired,
  currentJurisdiction: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  onGetCurrentJurisdiction: PropTypes.func.isRequired,
  onSaveContact: PropTypes.func.isRequired,
  onSavePlan: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  descendants_chart_statuses: PropTypes.arrayOf(PropTypes.shape({})),
  actions_chart_by_review: PropTypes.arrayOf(PropTypes.shape({})),
  actions_chart_by_status: PropTypes.arrayOf(PropTypes.shape({})),
};

JurisdictionPage.defaultProps = {
  descendants_chart_statuses: [],
  actions_chart_by_review: [],
  actions_chart_by_status: [],
};

const mapStateToProps = ({
  profile: {user: {role: userRole}},
  currentJurisdiction,
  currentJurisdiction: {
    actions,
    contact,
    descendants,
    plan,
    isFetching,
    descendants_chart_statuses,
    actions_chart_by_review,
    actions_chart_by_status,
    id
  }
}) => ({
  userRole,
  currentJurisdiction,
  actions,
  contact,
  descendants,
  plan,
  isFetching,
  descendants_chart_statuses,
  actions_chart_by_review,
  actions_chart_by_status,
  id
});

const bindActions = dispatch => ({
  onSaveContact: params => dispatch(saveJurisdiictionContact(params)),
  onSavePlan: params => dispatch(savePlan(params)),
  onGetCurrentJurisdiction: params => dispatch(getCurrentJurisdiction(params)),
});

export default connect(mapStateToProps, bindActions)(JurisdictionPage);
