import React, {memo, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Form, Spin, Empty} from 'antd';
import {
  getSearchOptions,
  getSearchJurisdictions,
  getSearchActions,
  getSearchItems,
  generateExcel,
} from '../../../actions/search';
import {SearchForm, ActionsSearchTable} from '../../../components';
import './index.css';

function SearchPage(props) {
  const {
    options,
    onGetSearchOptions,
    onGetSearchJurisdictions,
    onGetSearchActions,
    onGetSearchItems,
    onGenerateExcel,
    params,
    currentPage,
    actions,
    areParamsFetching,
    isSearchFetching,
    isExcelFetching,
    total,
    availableJurisdictions,
    searchSubmitted,
    sorterParams,
  } = props;

  useEffect(() => {
    if (!options) {
      onGetSearchOptions();
    }
  }, []);

  const renderSearchResults = () => {
    if (!searchSubmitted) {
      return null;
    }

    return actions.length
      ? (
        <ActionsSearchTable
          actions={actions}
          availableJurisdictions={availableJurisdictions}
          getSearchItems={onGetSearchItems}
          params={params}
          options={options}
          currentPage={currentPage}
          total={total}
          sorterParams={sorterParams}
        />
      )
      : (<Empty className="empty_gap"/>);
  };

  const SearchFormComponent = Form.create({name: 'action'})(SearchForm);

  return (
    <>
      {
        !options || !params
          ? (<div className="spin__wrapper"><Spin size="large"/></div>)
          : (
            <SearchFormComponent
              options={options}
              onGetSearchJurisdictions={onGetSearchJurisdictions}
              onGetSearchActions={onGetSearchActions}
              params={params}
              areParamsFetching={areParamsFetching}
              getSearchItems={onGetSearchItems}
              currentPage={currentPage}
              hasActions={actions.length > 0}
              onGenerateExcel={onGenerateExcel}
              isExcelFetching={isExcelFetching}
              isSearchFetching={isSearchFetching}
              sorterParams={sorterParams}
            />
          )
      }
      {
        !isSearchFetching && renderSearchResults()
      }
    </>
  );
}

SearchPage.propTypes = {
  options: PropTypes.shape({}).isRequired,
  onGetSearchOptions: PropTypes.func.isRequired,
  onGetSearchJurisdictions: PropTypes.func.isRequired,
  onGetSearchActions: PropTypes.func.isRequired,
  onGetSearchItems: PropTypes.func.isRequired,
  onGenerateExcel: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
  currentPage: PropTypes.number.isRequired,
  actions: PropTypes.shape({}).isRequired,
  areParamsFetching: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number.isRequired,
  availableJurisdictions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchSubmitted: PropTypes.bool.isRequired,
  isSearchFetching: PropTypes.bool.isRequired,
  isExcelFetching: PropTypes.bool.isRequired,
  sorterParams: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({
  search: {
    options,
    areParamsFetching,
    params,
    currentPage,
    actions,
    total,
    availableJurisdictions,
    searchSubmitted,
    isSearchFetching,
    isExcelFetching,
    sorterParams,
  }
}) => ({
  areParamsFetching,
  options,
  params,
  currentPage,
  actions,
  total,
  availableJurisdictions,
  searchSubmitted,
  isSearchFetching,
  isExcelFetching,
  sorterParams
});

const bindActions = dispatch => ({
  onGetSearchOptions: () => dispatch(getSearchOptions()),
  onGetSearchJurisdictions: params => dispatch(getSearchJurisdictions(params)),
  onGetSearchActions: params => dispatch(getSearchActions(params)),
  onGetSearchItems: params => dispatch(getSearchItems(params)),
  onGenerateExcel: params => dispatch(generateExcel(params)),
});

export default connect(mapStateToProps, bindActions)(memo(SearchPage));
