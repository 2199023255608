import React, {PureComponent} from 'react';
import {Modal, Button, Checkbox} from 'antd';
import PropTypes from 'prop-types';
import {HTMLContent} from '../common';
import content from '../../data/tos';

export default class ToSModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  state = {
    loading: false,
    visible: false,
    accepted: false
  }

  onAccept = (e) => {
    this.setState({accepted: e.target.checked});
  }

  handleOk = () => {
    this.setState({loading: true}, () => {
      const {onClose} = this.props;
      onClose();
    });
  }

  showModal() {
    this.setState({
      visible: true,
    });
  }

  render() {
    const {visible, accepted, loading} = this.state;
    return (
      <Modal
        visible={visible}
        title={<h2 style={{margin: 0}}>Terms of Service</h2>}
        width={640}
        closable={false}
        bodyStyle={{
          height: 400,
          overflowY: 'auto'
        }}
        footer={[
          <Checkbox onChange={this.onAccept} key="accept">Accept</Checkbox>,
          <Button
            loading={loading}
            key="submit"
            disabled={!accepted}
            type="primary"
            onClick={this.handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <HTMLContent content={content}/>
      </Modal>
    );
  }
}
