import React from 'react';
import {
  Layout,
  Typography,
  Dropdown,
  Button,
  Icon,
  Menu
} from 'antd';
import PropTypes from 'prop-types';
import './index.css';

const {
  Title
} = Typography;

const {
  Header,
} = Layout;

export default function MainHeader({onUserLogout, user, toggleProfile}) {
  const {username, first_name, last_name} = user;

  const displayName = first_name ? `${first_name} ${last_name}` : username;

  return (
    <Header className="main-header" style={{backgroundColor: '#EDEDED'}}>
      <Title className="main-header__title">
        BATool
        <sup>SM</sup>
        – Mitigation Module
      </Title>
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="1" disabled>
              <Icon type="lock"/>
              Logged in as:
              {displayName}
            </Menu.Item>
            <Menu.Item key="2" onClick={toggleProfile}>
              <Icon type="edit"/>
              Edit Profile
            </Menu.Item>
            <Menu.Item key="3">
              <Icon type="question-circle"/>
              Help
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="4" onClick={onUserLogout}>
              <Icon type="unlock"/>
              Log Out
            </Menu.Item>
          </Menu>
        )}
        className="main-header__user"
      >
        <Button>
          <Icon type="user"/>
          My Profile
          <Icon type="down"/>
        </Button>
      </Dropdown>
    </Header>
  );
}

MainHeader.propTypes = {
  onUserLogout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  toggleProfile: PropTypes.func.isRequired,
};
