import {
  AUTH_LOGOUT,
  AUTH_SUCCESS,
  AUTH_TOS_ACCEPT,
  PROFILE_GET_SUCCESS
} from '../actions/types';

import {createReducer} from '../utils';

const initialState = {
  token: null,
  user: null,
  isAuthenticated: false,
  terms: false
};
const authReducer = createReducer(initialState, {
  [AUTH_SUCCESS]: (state, {token, user}) => ({
    ...state,
    user,
    token,
    terms: user.terms,
  }),
  [AUTH_TOS_ACCEPT]: state => ({...state, isAuthenticated: true, terms: true}),
  [PROFILE_GET_SUCCESS]: (state, {user}) => ({...state, user}),
  [AUTH_LOGOUT]: () => initialState
});

export default authReducer;
