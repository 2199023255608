import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'antd/dist/antd.css';

ReactDOM.render(<App/>, document.getElementById('root'));

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}
