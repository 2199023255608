import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Form, Spin, Empty} from 'antd';
import './index.css';
import {
  getReportOptions,
  getReportJurisdictions,
  getReportItems,
  generateReport,
} from '../../../actions/report';
import {ReportForm, ActionsReportTable} from '../../../components';

function ReportsPage(props) {
  const {
    isReportRuns,
    availableJurisdictions,
    onGetReportOptions,
    onGetReportJurisdictions,
    onGetReportItems,
    onGenerateReport,
    searchSubmitted,
    isFetching,
    currentPage,
    actions,
    options,
    params,
    total,
  } = props;

  useEffect(() => {
    if (!options) {
      onGetReportOptions();
    }
  }, []);

  const renderSearchResults = () => {
    if (!searchSubmitted) {
      return null;
    }

    return actions.length
      ? (
        <ActionsReportTable
          availableJurisdictions={availableJurisdictions}
          onGetReportItems={onGetReportItems}
          currentPage={currentPage}
          total={total}
          params={params}
          options={options}
          actions={actions}
        />
      )
      : (<Empty className="empty_gap"/>);
  };

  const ReportFormComponent = Form.create({name: 'action'})(ReportForm);
  return (
    <>
      {
        !options || !params
          ? (<div className="spin__wrapper"><Spin size="large"/></div>)
          : (
            <ReportFormComponent
              onGetReportOptions={onGetReportOptions}
              onGetReportJurisdictions={onGetReportJurisdictions}
              onGetReportItems={onGetReportItems}
              isFetching={isFetching}
              params={params}
              hasActions={actions.length > 0}
              options={options}
              getReportItems={onGetReportItems}
              onGenerateReport={onGenerateReport}
              isReportRuns={isReportRuns}
            />
          )
      }
      {
        renderSearchResults()
      }
    </>
  );
}

ReportsPage.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}).isRequired,
  onGetReportOptions: PropTypes.func.isRequired,
  onGetReportJurisdictions: PropTypes.func.isRequired,
  onGetReportItems: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  availableJurisdictions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onGenerateReport: PropTypes.func.isRequired,
  searchSubmitted: PropTypes.bool.isRequired,
  isReportRuns: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  report: {
    options,
    params,
    isFetching,
    actions,
    currentPage,
    availableJurisdictions,
    total,
    searchSubmitted,
    isReportRuns,
  }
}) => ({
  options,
  params,
  isFetching,
  actions,
  currentPage,
  availableJurisdictions,
  total,
  searchSubmitted,
  isReportRuns,
});

const bindActions = dispatch => ({
  onGetReportOptions: () => dispatch(getReportOptions()),
  onGetReportJurisdictions: params => dispatch(getReportJurisdictions(params)),
  onGetReportItems: params => dispatch(getReportItems(params)),
  onGenerateReport: params => dispatch(generateReport(params)),
});

export default connect(mapStateToProps, bindActions)(ReportsPage);
