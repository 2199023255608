import ApiClient from '../../utils/api';
import {
  API_ERROR,
  PROFILE_GET_REQUEST,
  PROFILE_GET_SUCCESS,
  APP_HIDE_PROFILE
} from '../types';
import {API_PROFILE} from '../../config/constants';
import {showMessage} from '../application';

export function getProfile() {
  return async (dispatch) => {
    try {
      dispatch({type: PROFILE_GET_REQUEST});

      const user = await ApiClient.get(API_PROFILE);

      dispatch({type: PROFILE_GET_SUCCESS, user});
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

export function saveProfile(params) {
  return async (dispatch, getState) => {
    const {profile: {user}} = getState();
    try {
      dispatch({type: PROFILE_GET_REQUEST});

      await ApiClient.put(API_PROFILE, params);

      dispatch({type: PROFILE_GET_SUCCESS, user: {...user, ...params}});
      dispatch({type: APP_HIDE_PROFILE});
      dispatch(showMessage('Profile was successfully updated.'));
    } catch (e) {
      console.log(e.message);
      dispatch({type: API_ERROR, error: e.message});
    }
  };
}

