import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Table
} from 'antd';
import moment from 'moment';
import {Chart, Bars} from '../common';
import {JURSDICTION_PROGRESS_TABLE, ACTIONS_TABLE_CONFIG} from '../../config/constants';

function JurisdictionCharts(props) {
  const {plan, jurisdictions} = props;

  const jurisdictionalInfo = [];
  jurisdictionalInfo.push({
    name: 'Plan Approval Date',
    value: moment(plan.approval_date).format('MM/DD/YYYY'),
    field: 'approval_date'
  });
  jurisdictionalInfo.push({
    name: 'Plan Expiration Date',
    value: moment(plan.expiration_date).format('MM/DD/YYYY'),
    field: 'expiration_date'
  });
  jurisdictionalInfo.push({
    name: 'Review Cycle Frequency',
    value: plan.review_cycle_frequency,
    field: 'review_cycle_frequency',
  });
  jurisdictionalInfo.push({
    name: 'Review Cycle Open',
    value: moment(plan.review_start_date).format('MM/DD/YYYY'),
    field: 'review_start_date'
  });
  jurisdictionalInfo.push({
    name: 'Review Cycle Close',
    value: moment(plan.review_end_date).format('MM/DD/YYYY'),
    field: 'review_end_date'
  });
  // jurisdictionalInfo.push({
  //   name: 'Plan Update - Scheduled Start Date',
  //   value: moment(plan.start_date).format('MM/DD/YYYY'),
  //   field: 'start_date'
  // });
  // jurisdictionalInfo.push({
  //   name: 'Plan Update - Scheduled End Date',
  //   value: moment(plan.end_date).format('MM/DD/YYYY'),
  //   field: 'end_date'
  // });

  return (
    <div className="status__wrapper">
      <Card>
        <Row>
          <Col span={6} style={{height: '200px'}}>
            <Chart jurisdictionProgress={jurisdictions} isJurisdiction/>
          </Col>
          <Col span={8}>
            <Bars title="Reporting Progress" jurisdictionProgress={jurisdictions}/>
          </Col>
          <Col span={10} className="charts__table">
            <Table
              {...ACTIONS_TABLE_CONFIG}
              columns={JURSDICTION_PROGRESS_TABLE}
              dataSource={jurisdictionalInfo}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

JurisdictionCharts.propTypes = {
  jurisdictions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  plan: PropTypes.shape({}),
};

JurisdictionCharts.defaultProps = {
  plan: []
};

export default JurisdictionCharts;
