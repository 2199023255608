import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import {Table, Button, Icon} from 'antd';
import moment from 'moment';
import './index.css';
import {TABLE_PER_PAGE} from '../../config/constants';

const columns = [
  {
    title: 'Review',
    dataIndex: 'review',
    key: 'review',
    render: (name, entry) => (
      <Link to={`/action/${entry.id}`}>
        <Button className="jurisdiction__button" icon="edit" type="link"/>
      </Link>
    ),
    width: '5%'
  },
  {
    title: 'Action ID',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    },
    sortDirections: ['ascend', 'descend'],
    width: '10%'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (name, entry) => (<span className="clamp-3">{entry.description}</span>),
    sorter: (a, b) => {
      if (a.description > b.description) {
        return 1;
      }
      if (a.description < b.description) {
        return -1;
      }
      return 0;
    },
    sortDirections: ['ascend', 'descend'],
    width: '35%'
  },
  {
    title: 'Review Status',
    dataIndex: 'review_status',
    key: 'review_status',
    sorter: (a, b) => {
      if (a.review_status > b.review_status) {
        return 1;
      }
      if (a.review_status < b.review_status) {
        return -1;
      }
      return 0;
    },
    sortDirections: ['ascend', 'descend'],
    width: '10%'
  },
  {
    title: 'Lead Agency',
    dataIndex: 'lead_agency',
    key: 'lead_agency',
    sorter: (a, b) => {
      if (a.lead_agency > b.lead_agency) {
        return 1;
      }
      if (a.lead_agency < b.lead_agency) {
        return -1;
      }
      return 0;
    },
    sortDirections: ['ascend', 'descend'],
    width: '25%'
  },
  {
    title: 'Last Updated / Reviewed',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: date => moment(date).format('MM/DD/YYYY'),
    sorter: (a, b) => {
      if (a.updated_at > b.updated_at) {
        return 1;
      }
      if (a.updated_at < b.updated_at) {
        return -1;
      }
      return 0;
    },
    sortDirections: ['ascend', 'descend'],
    width: '15%'
  }
];

const ActionsTable = (props) => {
  const {actions, jurisdictionId} = props;

  return (
    <>
      <div className="status__table-note">
        <Link to={`/create/action/${jurisdictionId}`}>
          <Button><strong>Add New Action</strong></Button>
        </Link>
      </div>
      <div className="status__table-note">
        Click&nbsp;
        <Icon type="edit"/>
        &nbsp;to review and edit the action
      </div>
      <div className="table__wrapper">
        <Table columns={columns} dataSource={actions} pagination={{pageSize: TABLE_PER_PAGE}}/>
      </div>
    </>
  );
};

ActionsTable.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  jurisdictionId: PropTypes.number.isRequired,
};

export default withRouter(ActionsTable);
