import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import {Table, Button} from 'antd';
import moment from 'moment';
import {ACTIONS_TABLE_PER_PAGE} from '../../config/constants';
import './index.css';

const ActionsPaginationTable = (props) => {
  const {
    actions,
    onGetActions,
    currentPage,
    total,
    sorterParams,
  } = props;
  const [isLoading, toggleLoading] = useState(false);
  const [pager, setPager] = useState({
    total,
    current: currentPage,
    pageSize: ACTIONS_TABLE_PER_PAGE,
  });

  const sortOrder = sorterParams.order === 'asc' ? 'ascend' : 'descend';

  const columns = [
    {
      title: 'Review',
      dataIndex: 'review',
      key: 'review',
      render: (name, entry) => (
        <Link to={`/action/${entry.id}`}>
          <Button className="jurisdiction__button" icon="edit" type="link"/>
        </Link>
      ),
      width: '5%'
    },
    {
      title: 'Plan',
      dataIndex: 'plan_name',
      key: 'plan_name',
      width: '10%'
    },
    {
      title: 'Action ID',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sorterParams.field === 'name' && sortOrder,
      width: '10%'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: true,
      sortOrder: sorterParams.field === 'description' && sortOrder,
      width: '30%'
    },
    {
      title: 'Action Status',
      dataIndex: 'action_status',
      key: 'action_status_id',
      sorter: true,
      sortOrder: sorterParams.field === 'action_status_id' && sortOrder,
      width: '10%'
    },
    {
      title: 'Review Status',
      dataIndex: 'review_status',
      key: 'review_status_id',
      sortOrder: sorterParams.field === 'review_status_id' && sortOrder,
      sorter: true,
      width: '10%'
    },
    {
      title: 'Lead Agency',
      dataIndex: 'lead_agency',
      key: 'lead_agency',
      sortOrder: sorterParams.field === 'lead_agency' && sortOrder,
      sorter: true,
      width: '15%'
    },
    {
      title: 'Last Updated / Reviewed',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sortOrder: sorterParams.field === 'updated_at' && sortOrder,
      sorter: true,
      render: date => moment(date).format('MM/DD/YYYY'),
      width: '15%'
    }
  ];

  useEffect(() => {
    setPager({total});
  }, [total]);

  function handleTableChange(pagination, filters, sorter) {
    let {order, field} = sorterParams;

    if (Object.keys(sorter).length) {
      order = sorter.order === 'descend' ? 'desc' : 'asc';
      field = sorter.columnKey;
    } else {
      order = 'asc';
    }

    toggleLoading();
    onGetActions({
      page: pagination.current,
      size: ACTIONS_TABLE_PER_PAGE,
      currentPage: pagination.current,
      order: `actions.${field}_${order}`,
      sorterParams: {
        field,
        order
      }
    });

    setPager({...pagination});
    toggleLoading();
  }

  return (
    <div className="table__wrapper">
      <Table
        columns={columns}
        dataSource={actions}
        pagination={pager}
        onChange={handleTableChange}
        loading={isLoading}
      />
    </div>
  );
};

ActionsPaginationTable.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentPage: PropTypes.number,
  total: PropTypes.number.isRequired,
  onGetActions: PropTypes.func.isRequired,
  sorterParams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ActionsPaginationTable.defaultProps = {
  currentPage: 1,
};

export default withRouter(ActionsPaginationTable);
