import {
  APP_HIDE_PROFILE,
  APP_SHOW_PROFILE,
  APP_MESSAGE, API_ERROR
} from '../types';

export function toggleProfileForm() {
  return (dispatch, getState) => {
    const {application: {showProfileForm}} = getState();
    const ACTION = showProfileForm ? APP_HIDE_PROFILE : APP_SHOW_PROFILE;
    return dispatch({type: ACTION});
  };
}

export function showMessage(message) {
  return {type: APP_MESSAGE, message};
}

export function cleanError() {
  return {type: API_ERROR, error: null};
}
