import React from 'react';
import {
  Layout,
} from 'antd';
import ttLogo from '../../../assets/images/TT_logo-2.svg';
import './index.css';

const {
  Footer,
} = Layout;

const date = new Date();
const year = date.getFullYear();

export default function MainFooter() {
  return (
    <Footer className="main-footer" style={{backgroundColor: '#FFF'}}>
      <span className="main-footer__copy">
        Tetra Tech &copy;&nbsp;
        {year}
      </span>
      <a
        className="main-footer__logo"
        href="http://www.tetratech.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={ttLogo} alt=""/>
      </a>
    </Footer>
  );
}
