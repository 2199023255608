import omit from 'lodash/omit';
import {
  DASHBOARD_GET_REQUEST,
  JURISDICTION_GET_REQUEST,
  JURISDICTION_GET_SUCCESS,
  CONTACT_UPDATE_SUCCESS,
  PLAN_UPDATE_SUCCESS,
  DASHBOARD_PLANS_GET_SUCCESS,
  API_ERROR,
} from '../actions/types';

import {createReducer} from '../utils';

const initialState = {
  name: '',
  plan: {},
  contact: {},
  descendants: [],
  actions: [],
  plans: [],
  actions_chart_by_status: [],
  actions_chart_by_review: [],
  descendants_chart_statuses: [],
  isFetching: false,
};
const jurisdictionReducer = createReducer(initialState, {
  [DASHBOARD_GET_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [JURISDICTION_GET_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [DASHBOARD_PLANS_GET_SUCCESS]: (state, {plans}) => ({
    ...state,
    plans,
    isFetching: false,
  }),
  [JURISDICTION_GET_SUCCESS]: (state, {jurisdiction}) => ({
    ...state,
    ...jurisdiction.jurisdiction,
    ...omit(jurisdiction, 'jurisdiction'),
    isFetching: false
  }),
  [CONTACT_UPDATE_SUCCESS]: (state, {contact}) => ({
    ...state,
    contact
  }),
  [PLAN_UPDATE_SUCCESS]: (state, {plan}) => ({
    ...state,
    plan
  }),
  [API_ERROR]: state => ({...state, isFetching: false}),
});

export default jurisdictionReducer;
